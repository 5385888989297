.feed-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    width: 100%;
}

.feed-section .feed-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    overflow-y: scroll;
    width: 100%;
}

.feed-section .feed-box .post-wrapper {
    width: 100%;
}

.feed-section .feed-box .new-post-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 2;
}

.feed-section .feed-box .empty {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    justify-content: center;
    width: 100%;
    height: 90%;
}

.feed-section .feed-box{
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.feed-section .feed-box::-webkit-scrollbar {
    display: none;
}