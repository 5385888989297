.artist-promotion-track-card {
    align-items: center;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 8px 16px;
    width: 100%;
    user-select: none;
}

.artist-promotion-track-card .left-card-section {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 16px;
    padding: 0px 8px;
}

.artist-promotion-track-card .track-image {
    border-radius: 4px;
    height: 56px;
    width: 56px;
    object-fit: cover;
}

.artist-promotion-track-card .main-info {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 4px 0px;
    width: 0px;
}

.artist-promotion-track-card .track-name {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    color: var(--variable-collection-contrast-color);
    display: -webkit-box;
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
}

.artist-promotion-track-card .track-album {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    color: var(--variable-collection-contrast-color);
    display: -webkit-box;
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: 135%;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
}

.artist-promotion-track-card .right-card-section {
    align-items: center;
    display: inline-flex;
    gap: 16px;
}

.artist-promotion-track-card .track-duration {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.artist-promotion-track-card .playlist-link {
    all: unset;
    box-sizing: border-box;
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-line: underline;
    text-underline-offset: 3.5px;
}


.artist-promotion-track-card:hover {
    background-color: var(--variable-collection-primary-color-30);
}

.artist-promotion-track-card .circle-arrow-button > svg > path {
    color: var(--variable-collection-warning-color-60)
}

.artist-promotion-track-card .minus-button > svg > path {
    fill: var(--variable-collection-warning-color-60)
}

.artist-promotion-track-card .plus-button > svg > path {
    fill: var(--variable-collection-approve-color-60)
}