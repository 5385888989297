.sign-out-button {
    all: unset;
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.sign-out-button > svg > path {
    fill: var(--variable-collection-primary-color-60);
}

.sign-out-button:hover > svg > path {
    fill: var(--variable-collection-primary-color-95);
}