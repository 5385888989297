.progress-bar {
  background-color: var(--variable-collection-primary-color-15);
  border-radius: 8px;
  height: 16px;
  width: 100%;
}

.progress-bar .progress {
  background: var(--variable-collection-2-progress-gradient);
  border-radius: 8px;
  height: 16px;
  width: 0px;
}