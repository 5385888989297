.brief-stats-box {
  align-items: flex-start;
  background-color: var(--variable-collection-background-section-color);
  border: 1px solid;
  border-color: var(--variable-collection-section-stroke-color);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  padding: 16px;
  position: relative;
  min-width: 345px;
  max-width: 560px;
  width: 100%;
  user-select: none;
}

.brief-stats-box .top-section {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
}

.brief-stats-box .title {
  color: var(--variable-collection-contrast-color);
  font-family: var(--h5-primary-font-family);
  font-size: var(--h5-primary-font-size);
  font-style: var(--h5-primary-font-style);
  font-weight: var(--h5-primary-font-weight);
  letter-spacing: var(--h5-primary-letter-spacing);
  line-height: var(--h5-primary-line-height);
  white-space: nowrap;
  width: fit-content;
}

.brief-stats-box .subtitle {
  color: var(--variable-collection-primary-color-60);
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  white-space: nowrap;
  width: fit-content;
}

.brief-stats-box .stats-frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  width: 100%;
}

.brief-stats-box .stats {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  justify-content: center;
  height: 170px;
}

@media only screen and (max-width: 460px) {
  .brief-stats-box {
    min-width: 288px;
  }
}

@media only screen and (max-width: 370px) {
  .brief-stats-box {
    min-width: 288px;
    gap: 8px;
  }

  .brief-stats-box .stats {
    gap: 12px;
  }
}