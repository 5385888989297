.email-confirmation-box {
    align-items: center;
    border: 2px solid;
    border-color: var(--variable-collection-secondary-color-50);
    border-radius: 16px;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    overflow: hidden;
    padding: 16px 32px;
}

.email-confirmation-box .form {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
}

.email-confirmation-box .arist-link {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    width: 328px;
}

.email-confirmation-box .code-input {
    align-items: center;
    display: inline-flex;
    gap: 8px;
}

.email-confirmation-box .info-section {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.email-confirmation-box .p {
    align-self: stretch;
    color: var(--variable-collection-primary-color-95);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    text-align: center;
}

.email-confirmation-box .options {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.email-confirmation-box .note {
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    text-align: center;
}

.email-confirmation-box .text-button {
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    text-align: center;
    cursor: pointer;
}

.email-confirmation-box .text-button:hover {
    text-decoration: underline;
}

.email-confirmation-box .text-box-wrapper {
    color: var(--variable-collection-secondary-color-65);
    background-color: var(--variable-collection-primary-color-20);
    border: 1px solid;
    border-color: var(--variable-collection-primary-color-35);
    border-radius: 8px;
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    height: 48px;
    width: 40px;
    text-align: center;
    white-space: nowrap;
}

.email-confirmation-box .text-box-wrapper:invalid {
    border-color: var(--variable-collection-warning-color-50);
}

.email-confirmation-box .text-box-wrapper.invalid {
    color: var(--variable-collection-warning-color-50);
}

.email-confirmation-box .text-box-wrapper:focus {
    outline: none;
}

.email-confirmation-box .alert {
    color: var(--variable-collection-warning-color-50);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: 500;
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    text-align: center;
}

.email-confirmation-box .primary-button {
    width: 40px;
    height: 48px;
}

.email-confirmation-box .button-text-wrapper>svg>path {
    color: var(--variable-collection-primary-color-20);
}

@media only screen and (max-width: 400px) {
    .email-confirmation-box {
        padding: 16px 16px;
    }

    .email-confirmation-box .button-text-wrapper>svg {
        width: 24px;
        height: 24px;
    }

    .email-confirmation-box .button-text-wrapper .loader-box-icon {
        width: 18px;
        height: 18px;
    }

    .email-confirmation-box .text-box-wrapper {
        width: 32px;
    }
    .email-confirmation-box .primary-button {
        width: 32px;
    }
    .email-confirmation-box .arist-link {
        width: 272px;
        gap: 8px;
    }
}