.post {
    align-items: flex-start;
    background-color: var(--variable-collection-background-section-color);
    border: 1px solid;
    border-color: var(--variable-collection-section-stroke-color);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    padding: 16px;
    width: 100%;
    cursor: pointer;
}

.post:hover {
    background-color: var(--variable-collection-background-section-color-80);
}

.post .reply-header,
.post .repost-header {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 4px;
    width: 100%;
}

.post .repost-header>svg>path {
    color: var(--variable-collection-warning-color-60);
}

.post .repost-text-wrapper {
    color: var(--variable-collection-warning-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.post .reply-header>svg>path {
    color: var(--variable-collection-secondary-color-60);
}

.post .reply-text-wrapper {
    color: var(--variable-collection-secondary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.post .top-section {
    align-self: stretch;
    display: flex;
    gap: 8px;
    width: 100%;
}

.post .div-2 {
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    gap: 8px;
    width: 100%;
}

.post .div-2.original {
    flex-direction: column;
    padding-left: 0px !important;
}

.post .div-2.original .right-section {
    padding-left: 8px;
    padding-right: 8px;
}

.post .div-2.reply,
.post .div-2.repost {
    background-color: var(--variable-collection-background-section-color);
    padding: 8px;
    padding-right: 0px;
    padding-bottom: 0px;
    border: 1px solid;
    border-radius: 8px;
}

.post .div-2.reply:hover,
.post .div-2.repost:hover {
    background-color: var(--variable-collection-primary-color-15-15);
    cursor: pointer;
}

.post .div-2.repost {
    border-color: var(--variable-collection-primary-color-30);
}

.post .div-2.reply {
    border-color: var(--variable-collection-primary-color-30);
}

.post .left-section {
    align-items: flex-start;
    align-self: stretch;
    display: inline-flex;
    justify-content: center;
}

.post .profile-icon {
    border-radius: 40px;
    height: 40px;
    width: 40px;
    user-select: none;
}

.post .profile-icon.small {
    border-radius: 40px;
    height: 24px;
    width: 24px;
}

.post .profile-icon.default {
    border: 1px solid;
    border-color: var(--variable-collection-primary-color-35);
}

.post .right-section {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 4px;
    width: 100%;
}

.post .main-section {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    width: 100%;
}

.post .profile-header {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
}

.post .header-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
}

.post .profile-info {
    align-items: center;
    display: inline-flex;
    gap: 8px;
}

.post .name {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-weight: 500;
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.post .info {
    align-items: center;
    display: inline-flex;
    gap: 4px;
    justify-content: center;
}

.post .info-text {
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.info-text.date {
    white-space: nowrap;
}

.post .name,
.post .info-text.username {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-all; 
}

.post .post-content {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: 100%;
}

.post .content-text {
    align-self: stretch;
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    word-break: break-word;
    white-space: pre-line;
    word-wrap: break-word;
}

.post .content-text .highlighted-tag {
    color: var(--variable-collection-secondary-color-75);
}

.post .content-text > a {
    color: var(--variable-collection-secondary-color-75);
}

.post .options {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 32px;
    margin-top: 12px;
    width: 100%;
}

.post .stats {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    justify-content: space-between;
}

.post .stats button {
    width: 22px;
    height: 22px;
}

.post .actions {
    align-items: center;
    display: inline-flex;
}

.post .post-stat-card {
    align-items: center;
    display: inline-flex;
    gap: 8px;
    min-width: 54px;
}

.post .popup-wrapper {
    display: flex;
    align-items: center;
}

.post .post-stat-card.likes,
.post .post-stat-card.reposts,
.post .post-stat-card.replies {
    cursor: pointer;
}

.post .post-stat-card.likes:active,
.post .post-stat-card.reposts:active,
.post .post-stat-card.replies:active {
    opacity: 0.5;
}

.post .stat-text-wrapper {
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.post .post-stat-card.likes:hover .stat-text-wrapper,
.post .post-stat-card.likes.active .stat-text-wrapper,
.post .post-stat-card.likes:hover .like-button>svg>path {
    color: var(--variable-collection-error-color-60);
}

.post .post-stat-card.reposts:hover .stat-text-wrapper,
.post .post-stat-card.reposts.active .stat-text-wrapper,
.post .post-stat-card.reposts:hover .repost-button>svg>path {
    color: var(--variable-collection-warning-color-60);
}

.post .post-stat-card.replies:hover .stat-text-wrapper,
.post .post-stat-card.replies.active .stat-text-wrapper,
.post .post-stat-card.replies:hover .reply-button>svg>path {
    color: var(--variable-collection-secondary-color-60);
}

.post .post-stat-card.views {
    cursor: auto;
}

.post .post-stat-card.views>svg>path {
    color: var(--variable-collection-primary-color-60);
}

.post .post-unavailable {
    align-items: center;
    border: 1px solid;
    border-color: var(--variable-collection-primary-color-35);
    border-radius: 8px;
    display: inline-flex;
    gap: 8px;
    padding: 8px;
    cursor: auto;
}

.post .post-unavailable .unavailable-post-wrapper {
    color: var(--variable-collection-primary-color-35);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.post .edited-div {
    align-items: center;
    display: flex;
    gap: 4px;
}

.post .edited-div > svg > path {
    fill: var(--variable-collection-primary-color-60);
}

.post .edited-label {
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

@media only screen and (max-width: 800px) {
    .post .info-text.username {
        display: none;
    }
}  

@media only screen and (max-width: 340px) {
    .post .options {
        gap: 0px;
    }
}   

@media only screen and (max-width: 330px) {
    .post .profile-icon {
        border-radius: 32px;
        height: 32px;
        width: 32px;
    }
}   