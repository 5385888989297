.video-player {
    display: inline-block;
    position: relative;
    flex-grow: 1;
}

.video-player .video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.video-player .controls {
    position: absolute;
    bottom: 0px;
    align-items: center;
    background-color: var(--variable-collection-primary-color-5);
    display: flex;
    gap: 16px;
    padding: 8px 16px;
    width: 100%;
}

.video-player .full-screen-button,
.video-player .mute-button,
.video-player .play-button {
    all: unset;
    box-sizing: border-box;
    display: flex;
    cursor: pointer;
}

.video-player .controls .slider {
    align-items: flex-end;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    justify-content: flex-end;
}

.video-player .controls .time {
    align-items: flex-start;
    display: inline-flex;
    overflow: hidden;
}

.video-player .controls .text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: 12px;
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.video-player .hero {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translateY(50%) translateX(-50%);
    align-items: center;
    display: flex;
    justify-content: center;
}

.video-player .hero .play-button {
    align-items: center;
    background-color: var(--variable-collection-primary-color-5-75);
    border: 2px solid;
    border-color: var(--variable-collection-contrast-color);
    border-radius: 64px;
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    padding: 16px;
    cursor: pointer;
}

.video-player .video-header {
    position: absolute;
    top: 0px;
    right: 0px;
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    padding: 8px;
}

.video-player .video-header .video-close-button {
    all: unset;
    box-sizing: border-box;
    align-items: center;
    background-color: var(--variable-collection-primary-color-5-75);
    border-radius: 36px;
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    padding: 8px;
    cursor: pointer;
}

.video-player .mute-button:active,
.video-player .play-button:active,
.video-player .video-header .video-close-button:active,
.video-player .hero .play-button:active {
    opacity: 0.7;
}

.video-player svg.loader-box-icon,
.video-player svg > path {
    fill: var(--variable-collection-contrast-color);
    color: var(--variable-collection-contrast-color);
}


.video-player .controls .slider-bar {
    width: 100%;
}

.video-player .controls input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 3px;
    background: var(--variable-collection-contrast-color-5);
    outline: none;
}

.video-player .controls input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    background: var(--variable-collection-contrast-color);
    cursor: pointer;
    border-radius: 50%;
}

.video-player .controls input[type=range]::-ms-thumb,
.video-player .controls input[type="range"]::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background: var(--variable-collection-contrast-color);
    cursor: pointer;
    border-radius: 50%;
}