.sigmund-bucket-card {
    align-items: center;
    border-radius: 4px;
    display: flex;
    gap: 4px;
    height: 64px;
    width: 100%;
}

.sigmund-bucket-card .bucket {
    align-items: center;
    align-self: stretch;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    padding: 0px 8px;
    min-width: 8px;
    user-select: none;
}

.sigmund-bucket-card .bucket:hover {
    opacity: 0.8;
}

.sigmund-bucket-card .bucket.on-air {
    background-color: var(--variable-collection-secondary-color-75);
    border-radius: 4px 2px 2px 4px;
}

.sigmund-bucket-card .bucket.scheduled {
    background-color: var(--variable-collection-secondary-color-50);
}

.sigmund-bucket-card .bucket.revoked {
    background-color: var(--variable-collection-warning-color-70);
}

.sigmund-bucket-card .bucket.free {
    background-color: var(--variable-collection-primary-color-15);
    border-radius: 2px 4px 4px 2px;
}

.sigmund-bucket-card .text-wrapper {
    align-self: stretch;
    color: var(--variable-collection-primary-color-15);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: 600;
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    text-align: center;
}

.sigmund-bucket-card .tip-text-wrapper {
    align-self: stretch;
    color: var(--variable-collection-contrast-color);
    font-family: var(--p-primary-font-family);
    font-size: var(--p-primary-font-size);
    font-style: var(--p-primary-font-style);
    font-weight: var(--p-primary-font-style);
    letter-spacing: var(--p-primary-letter-spacing);
    line-height: var(--p-primary-line-height);
    text-align: left;
}

.sigmund-bucket-card .tip-value-on-air {
    font-weight: 550;
    color: var(--variable-collection-secondary-color-75);
}

.sigmund-bucket-card .tip-value-scheduled {
    font-weight: 550;
    color: var(--variable-collection-secondary-color-50);
}

.sigmund-bucket-card .tip-value-revoked {
    font-weight: 550;
    color: var(--variable-collection-warning-color-70);
}

.sigmund-bucket-card .tip-value-free {
    font-weight: 550;
    color: var(--variable-collection-approve-color-60);
}

.sigmund-bucket-card .text-wrapper.light {
    color: var(--variable-collection-contrast-color);
    font-weight: 500;
}

.sigmund-bucket-card .tool-tip-style {
    background-color: var(--variable-collection-primary-color-10);
    border: 2px solid var(--variable-collection-primary-color-5);
    border-radius: 8px;
}

@media only screen and (max-width: 380px) {
    .sigmund-bucket-card .text-wrapper {
        align-self: stretch;
        color: var(--variable-collection-primary-color-15);
        font-family: var(--p-primary-font-family);
        font-size: var(--p-primary-font-size);
        font-style: var(--p-primary-font-style);
        font-weight: 600;
        letter-spacing: var(--p-primary-letter-spacing);
        line-height: var(--p-primary-line-height);
        text-align: center;
    }    
  }