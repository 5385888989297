.notification-overlay {
    align-items: flex-start;
    background-color: var(--variable-collection-background-section-color);
    border: 2px solid;
    border-color: var(--variable-collection-secondary-color-50);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 283px;
    overflow: hidden;
    padding: 16px;
    width: 459px;
    max-width: 500px;
}

.notification-overlay .top-section {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.notification-overlay .notifications {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1;
    padding-bottom: 16px;
    padding-right: 8px;
    align-self: stretch;
    overflow-y: scroll;
}

.notification-overlay .label-text-wrapper {
    color: var(--variable-collection-primary-color-95);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.notification-overlay .text-wrapper {
    color: var(--variable-collection-primary-color-95);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    text-align: center;
    width: 100%;
}

.notification-overlay .empty-box {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    justify-content: center;
    padding: 16px;
    width: 100%;
}

.notification-overlay .top-section .close-button>svg>path {
    fill: var(--variable-collection-primary-color-60);
}

.notification-overlay .top-section .close-button:hover>svg>path {
    fill: var(--variable-collection-primary-color-95);
}

.notification-overlay ::-webkit-scrollbar {
    width: 16px;
}

.notification-overlay ::-webkit-scrollbar-track {
    background: transparent;
}

.notification-overlay ::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: inset 0 0 16px var(--variable-collection-primary-color-30);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
}

.notification-overlay ::-webkit-scrollbar-thumb:hover {
    background: transparent;
    box-shadow: inset 0 0 16px var(--variable-collection-primary-color-35);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
}

@media only screen and (max-width: 692px) {
    .notification-overlay .text-wrapper {
        font-family: var(--h6-primary-font-family);
        font-size: var(--h6-primary-font-size);
        font-style: var(--h6-primary-font-style);
        font-weight: var(--h6-primary-font-weight);
        letter-spacing: var(--h6-primary-letter-spacing);
        line-height: var(--h6-primary-line-height);
    }

    .notification-overlay .empty-box>svg {
        width: 60px;
        height: 60px;
    }
}