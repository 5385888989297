.on-air-playlists {
  align-items: flex-start;
  background-color: var(--variable-collection-background-section-color);
  border: 1px solid;
  border-color: var(--variable-collection-section-stroke-color);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  padding: 16px;
  min-width: 345px;
  max-width: 560px;
  width: 100%;
  user-select: none;
}

.on-air-playlists .text-wrapper {
  color: var(--variable-collection-contrast-color);
  font-family: var(--h5-primary-font-family);
  font-size: var(--h5-primary-font-size);
  font-style: var(--h5-primary-font-style);
  font-weight: var(--h5-primary-font-weight);
  letter-spacing: var(--h5-primary-letter-spacing);
  line-height: var(--h5-primary-line-height);
  white-space: nowrap;
  width: fit-content;
}

@media only screen and (max-width: 460px) {
  .on-air-playlists {
    min-width: 288px;
  }
}