.pick-genres-box {
  align-items: flex-start;
  background-color: var(--variable-collection-background-section-color);
  border: 2px solid;
  border-color: var(--variable-collection-secondary-color-50);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 16px;
  width: 100%;
  min-width: 345px;
  max-width: 560px;
}

.pick-genres-box .upper-section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.pick-genres-box .padding-container {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    width: 100%;
}

.pick-genres-box .title {
  align-self: stretch;
  color: var(--variable-collection-contrast-color);
  font-family: var(--h5-primary-font-family);
  font-size: var(--h5-primary-font-size);
  font-style: var(--h5-primary-font-style);
  font-weight: var(--h5-primary-font-weight);
  letter-spacing: var(--h5-primary-letter-spacing);
  line-height: var(--h5-primary-line-height);
}

.pick-genres-box .selector {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 8px;
  width: 100%;
}

.pick-genres-box .selected-genres {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  width: 100%;
}

.pick-genres-box  .text-box-wrapper {
    color: var(--variable-collection-primary-color-60);
    background-color: var(--variable-collection-primary-color-20);
    border: 1px solid;
    border-color: var(--variable-collection-primary-color-35);
    border-radius: 8px;
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    height: 48px;
    padding: 8px 16px;
    width: 100%;
}

.pick-genres-box  .text-box-wrapper:hover {
    border-color: var(--variable-collection-primary-color-60);
}

.pick-genres-box  .text-box-wrapper:invalid {
    border-color: var(--variable-collection-warning-color-50);
}

.pick-genres-box  .text-box-wrapper.invalid {
    border-color: var(--variable-collection-warning-color-50);
}

.pick-genres-box  .text-box-wrapper:focus {
    outline: none;
}

.pick-genres-box .text-box-wrapper:disabled, .pick-genres-box .text-box-wrapper[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

@media only screen and (max-width: 460px) {
  .pick-genres-box {
    min-width: 288px;
  }
}

@media only screen and (max-width: 380px) {
  .pick-genres-box .text-box-wrapper {
      height: 40px;
  }
}