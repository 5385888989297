.email-confirmation-frame {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    justify-content: center;
    width: 100%;
    padding: 16px;
  }
  
  .email-confirmation-frame .email-confirmation-section {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
  }
  
  .email-confirmation-frame .header {
    align-items: center;
    background-color: transparent;
    display: inline-flex;
  }
  
  .email-confirmation-frame .title {
    text-align: center;
    color: var(--variable-collection-contrast-color);
    font-family: var(--h3-primary-font-family);
    font-size: var(--h3-primary-font-size);
    font-style: var(--h3-primary-font-style);
    font-weight: var(--h3-primary-font-weight);
    letter-spacing: var(--h3-primary-letter-spacing);
    line-height: var(--h3-primary-line-height);
  }
  
  @media only screen and (max-width: 590px) {
    .email-confirmation-frame .title {
      font-family: var(--h4-primary-font-family);
      font-size: var(--h4-primary-font-size);
      font-style: var(--h4-primary-font-style);
      font-weight: var(--h4-primary-font-weight);
      letter-spacing: var(--h4-primary-letter-spacing);
      line-height: var(--h4-primary-line-height);
    }
  }