.broom-button {
    all: unset;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    cursor: pointer;
    border: 1px solid;
    border-color: var(--variable-collection-warning-color-60);
    border-radius: 8px;
}

.broom-button > svg > path {
    fill: var(--variable-collection-warning-color-60);
}

.broom-button:active {
    opacity: 50%;
}

.broom-button.disabled {
    opacity: 50%;
    cursor: auto;
}