.info-card {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
}

.info-card .title {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.info-card .subtitle {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h3-primary-font-family);
    font-size: var(--h3-primary-font-size);
    font-style: var(--h3-primary-font-style);
    font-weight: var(--h3-primary-font-weight);
    letter-spacing: var(--h3-primary-letter-spacing);
    line-height: var(--h3-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}