.artist-promotion-tracks-box {
  align-items: flex-start;
  background-color: var(--variable-collection-background-section-color);
  border: 1px solid;
  border-color: var(--variable-collection-section-stroke-color);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  overflow: hidden;
  min-width: 345px;
  max-width: 560px;
  width: 100%;
  height: 100%;
}

.artist-promotion-tracks-box .box-top-section {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 16px;
  padding: 0px 0px 0px 16px;
  width: 100%;
}

.artist-promotion-tracks-box .title {
  color: var(--variable-collection-contrast-color);
  font-family: var(--h5-primary-font-family);
  font-size: var(--h5-primary-font-size);
  font-style: var(--h5-primary-font-style);
  font-weight: var(--h5-primary-font-weight);
  letter-spacing: var(--h5-primary-letter-spacing);
  line-height: var(--h5-primary-line-height);
  white-space: nowrap;
  width: fit-content;
}

.artist-promotion-tracks-box .subtitle {
  color: var(--variable-collection-contrast-color);
  font-family: var(--h5-primary-font-family);
  font-size: var(--h5-primary-font-size);
  font-style: var(--h5-primary-font-style);
  font-weight: var(--h5-primary-font-weight);
  letter-spacing: var(--h5-primary-letter-spacing);
  line-height: var(--h5-primary-line-height);
  text-align: center;
  width: fit-content;
}

.artist-promotion-tracks-box .cards-section {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.artist-promotion-tracks-box .section {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
  height: 0px;
}

.artist-promotion-tracks-box .track-box {
  align-self: stretch;
  overflow: hidden;
  overflow-y: scroll;
  padding: 8px 0px;
  width: 100%;
}

.artist-promotion-tracks-box hr.separator-line {
  all: unset;
  width: 256px;
  height: 2px;
  color: var(--variable-collection-primary-color-35);
  background-color: var(--variable-collection-primary-color-35);
  border-color: var(--variable-collection-primary-color-35);
}

.artist-promotion-tracks-box .empty {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  justify-content: center;
  padding-left: 16px;
  width: 100%;
  height: 90%;
}

.artist-promotion-tracks-box .end-card {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 8px;
  padding-left: 16px;
}

.artist-promotion-tracks-box .track-box .primary-button {
  min-height: 32px;
  padding: 4px 12px;
  width: 50%;
  border-radius: 4px;
}

.artist-promotion-tracks-box .track-box .primary-button .button-text-wrapper {
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
}

.artist-promotion-tracks-box ::-webkit-scrollbar {
  width: 16px;
}

.artist-promotion-tracks-box ::-webkit-scrollbar-track {
  background: transparent;
}

.artist-promotion-tracks-box ::-webkit-scrollbar-thumb {
  background: transparent;
  box-shadow: inset 0 0 16px var(--variable-collection-primary-color-30);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.artist-promotion-tracks-box ::-webkit-scrollbar-thumb:hover {
  background: transparent;
  box-shadow: inset 0 0 16px var(--variable-collection-primary-color-35);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

@media only screen and (max-width: 380px) {
  .artist-promotion-tracks-box .empty>svg {
    width: 60px;
    height: 60px;
  }

  .artist-promotion-tracks-box .subtitle {
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
  }
}