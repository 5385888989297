.chats-box {
    align-items: flex-end;
    background-color: var(--variable-collection-background-section-color);
    border: 1px solid;
    border-color: var(--variable-collection-section-stroke-color);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    padding: 8px;
    height: 100%;
    min-width: 320px;
    width: 320px;
}

.chats-box .top-section {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    padding: 0px 0px 0px 12px;
    width: 100%;
}

.chats-box .chats {
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 4px;
    width: 100%;
    align-self: stretch;
    overflow: hidden;
    overflow-y: scroll;
}

.chats-box .empty-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.chats-box .empty-box .text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
}

.chats-box ::-webkit-scrollbar {
    width: 16px;
}

.chats-box ::-webkit-scrollbar-track {
    background: transparent;
}

.chats-box ::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: inset 0 0 16px var(--variable-collection-primary-color-30);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
}

.chats-box ::-webkit-scrollbar-thumb:hover {
    background: transparent;
    box-shadow: inset 0 0 16px var(--variable-collection-primary-color-35);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
}

@media only screen and (max-width: 1049px) {
    .chats-box {
        width: 100%;
        min-width: auto;
    }
}