.message-card {
    align-items: flex-start;
    display: flex;
    gap: 4px;
}

.message-card .message-frame {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 4px;
    justify-content: center;
    opacity: 0.8;
    padding: 8px 0px 4px 16px;
}

.message-card .message-frame.selected {
    border: 1px solid;
    border-color: var(--variable-collection-secondary-color-75);
}

.message-card .message {
    align-items: center;
    align-self: stretch;
    display: flex;
    padding: 0px 16px 0px 0px;
    width: 100%;
}

.message-card .message-text {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    word-wrap: break-word;
    word-break: break-word;
    white-space: pre-line
}

.message-card .indicators {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 4px;
    height: 16px;
    justify-content: flex-end;
    width: 100%;
}

.message-card .indicators > svg > path {
    fill: var(--variable-collection-secondary-color-50);
}

.message-card .indication-text {
    color: var(--variable-collection-contrast-color);
    font-family: var(--p-primary-font-family);
    font-size: var(--p-primary-font-size);
    font-style: var(--p-primary-font-style);
    font-weight: var(--p-primary-font-weight);
    letter-spacing: var(--p-primary-letter-spacing);
    line-height: var(--p-primary-line-height);
}

.message-card.left {
    justify-content: flex-end;
    margin-right: 40px;
}

.message-card.right {
    margin-left: 40px;
}


.message-card.left .message {
    gap: 10px;
}

.message-card.right .message-frame {
    background-color: hsl(208, 56%, 46%);
    border-radius: 8px 8px 0px 8px;
}

.message-card.right.stacked .message-frame,
.message-card.left.stacked .message-frame {
    border-radius: 8px 8px 8px 8px;
}

.message-card.left .message-frame {
    background-color: var(--variable-collection-primary-color-35);
    border-radius: 8px 8px 8px 0px;
}

.message-card.right .indicators {
    padding: 0px 4px;
}

.message-card.left .indicators {
    padding: 0px 8px;
}

.message-card .message-text > a {
    color: var(--variable-collection-secondary-color-75);
}