.url-preview {
    all: unset;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    align-items: flex-start;
    border: 1px solid;
    border-color: var(--variable-collection-primary-color-30);
    background-color: var(--variable-collection-background-section-color);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

.url-preview:hover {
    background-color: var(--variable-collection-primary-color-15-15);
    cursor: pointer;
}

.url-preview .url-image {
    flex: 1;
    flex-grow: 1;
    object-fit: cover;
    width: 100%;
}

.url-preview .url-info {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 4px;
    width: 100%;
}

.url-preview .url-main-info {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 4px;
    width: 100%;
}

.url-preview .text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.url-preview .text-wrapper.description {
    overflow-wrap: break-word;
    word-wrap: break-word;
    width: 100%;
}

.url-preview .text-wrapper.truncate {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-all; 
}

.url-preview .text-wrapper.shadow {
    color: var(--variable-collection-primary-color-60) !important;
}
