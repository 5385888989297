.counter {
    align-items: center;
    display: inline-flex;
    gap: 4px;
    user-select: none;
}

.counter .number-box {
    outline: none;
    pointer-events: none;
    align-items: center;
    background-color: var(--variable-collection-primary-color-20);
    border: 2px solid;
    border-color: var(--variable-collection-primary-color-35);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.counter .counter-text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.counter .buttons {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
}

.counter.mobile .number-box {
    height: 56px;
    width: 56px;
}

.counter.desktop .number-box {
    width: 48px;
    height: 40px;
}

.counter.mobile .buttons .arrow-button {
    width: 32px;
    height: 24px;
}

.counter.desktop .buttons .arrow-button {
    width: 24px;
    height: 16px;
}

.counter > input::-webkit-outer-spin-button,
.counter > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.counter > input[type=number] {
    -moz-appearance:textfield;
}