.media-button {
    all: unset;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    cursor: pointer;
    border: 1px solid;
    border-color: var(--variable-collection-secondary-color-75);
    border-radius: 8px;
}

.media-button > svg > path {
    fill: var(--variable-collection-secondary-color-75);
}

.media-button:active {
    opacity: 50%;
}

.media-button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}