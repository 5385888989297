.curator-request-overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    align-items: flex-start;
    background-color: var(--variable-collection-background-overlay-color);
    border: 2px solid;
    border-color: var(--variable-collection-primary-color-15);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: hidden;
    padding: 16px;
    width: 640px;
    z-index: 1000;
}

.curator-request-overlay .section {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.curator-request-overlay .title {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.curator-request-overlay .text-box {
    outline: none;
    resize: none;
    border: none;
    overflow: scroll;
    overflow-x: hidden;
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--variable-collection-primary-color-20);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    height: 180px;
    width: 100%;
}

.curator-request-overlay .text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.curator-request-overlay .text-wrapper.warning {
    align-self: auto;
    color: var(--variable-collection-warning-color-60);
}

.curator-request-overlay .bottom-section {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.curator-request-overlay .settings {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.curator-request-overlay .stock-section {
    align-items: flex-end;
    display: inline-flex;
    gap: 16px;
}

.curator-request-overlay .stock {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
}


.curator-request-overlay .primary-button {
    width: 312px;
}

.curator-request-overlay .primary-button svg.loader-box-icon {
    fill: var(--variable-collection-primary-color-25);
}

.curator-request-overlay .additional-section {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.curator-request-overlay .save-template {
    align-items: center;
    display: inline-flex;
    gap: 4px;
}

.curator-request-overlay .info-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
}

.curator-request-overlay .info {
    width: 400px;
}

.curator-request-overlay .link {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    color: var(--variable-collection-secondary-color-50);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.curator-request-overlay .link:hover {
    text-decoration: underline;
}

.curator-request-overlay .close-button>svg>path {
    fill: var(--variable-collection-primary-color-60);
}

.curator-request-overlay .close-button:hover>svg>path {
    fill: var(--variable-collection-primary-color-95);
}

.curator-request-overlay .text-box::-webkit-scrollbar {
    width: 8px;
}

.curator-request-overlay .text-box::-webkit-scrollbar-track {
    background: none;
}

.curator-request-overlay .text-box::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: inset 0 0 16px var(--variable-collection-primary-color-30);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
}

.curator-request-overlay .text-box::-webkit-scrollbar-thumb:hover {
    background: transparent;
    box-shadow: inset 0 0 16px var(--variable-collection-primary-color-35);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 0px solid transparent;
    border-right: 2px solid transparent;
}

.curator-request-overlay .stock-card.trial .label {
    color: var(--variable-collection-approve-color-60);
}

@media only screen and (max-width: 692px) {
    .curator-request-overlay .bottom-section {
        gap: 16px;
    }

    .curator-request-overlay .stock-section {
        justify-content: space-between;
        width: 100%;
    }

    .curator-request-overlay .primary-button {
        width: 100%;
    }

    .curator-request-overlay .info-section {
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4px;
        flex-direction: column;
    }
}

@media only screen and (min-width: 617px) and (max-width: 692px) {
    .curator-request-overlay {
        width: 440px;
    }
}

@media only screen and (min-width: 461px) and (max-width: 616px) {
    .curator-request-overlay {
        width: 375px;
    }
    .curator-request-overlay .info {
        width: 100%;
    }
}

@media only screen and (min-width: 381px) and (max-width: 460px) {
    .curator-request-overlay {
        width: 350px;
    }
    .curator-request-overlay .text-box {
        height: 140px;
    }
    .curator-request-overlay .info {
        width: 100%;
    }
}

@media only screen and (max-width: 380px) {
    .curator-request-overlay {
        width: 290px;
    }
    .curator-request-overlay .text-box {
        height: 140px;
    }
    .curator-request-overlay .info {
        width: 100%;
    }
}