.new-chat-button {
    all: unset;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    min-height: 40px;
    cursor: pointer;
    background-color: var(--variable-collection-secondary-color-65);
    border-radius: 8px;
    transition: all 200ms ease-in-out;
}

.new-chat-button > svg > path {
    fill: var(--variable-collection-primary-color-15);
}

.new-chat-button:hover {
    opacity: 90%;
}

.new-chat-button:active {
    opacity: 50%;
}

