.primary-button-v1 {
    all: unset;
    box-sizing: border-box;
    align-items: center;
    border: 1px solid;
    border-color: var(--variable-collection-primary-color-35);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 16px 32px;
    cursor: pointer;
}

.primary-button-v1 .text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.primary-button-v1:active {
    opacity: 0.7;
}

.primary-button-v1:hover {
    border-color: var(--variable-collection-primary-color-30);
}