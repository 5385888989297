.messenger-top-section {
    align-items: center;
    background-color: var(--variable-collection-background-section-color);
    border: 1px solid;
    border-color: var(--variable-collection-section-stroke-color);
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
}

.messenger-top-section .user-info {
    align-items: center;
    display: inline-flex;
    gap: 16px;
}


.messenger-top-section .text-wrapper {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: center;
    color: var(--variable-collection-contrast-color);
    display: -webkit-box;
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
}

.messenger-top-section .role {
    align-items: center;
    border-radius: 8px;
    display: inline-flex;
    overflow: hidden;
    padding: 6px 16px;
}

.messenger-top-section .div {
    color: var(--variable-collection-secondary-color-75);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.messenger-top-section .actions {
    align-items: center;
    display: inline-flex;
    gap: 16px;
    justify-content: center;
}

.messenger-top-section .chat-icon {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    outline: 1px solid var(--variable-collection-primary-color-60);
    user-select: none;
}

@media only screen and (min-width: 1050px) {
    .messenger-top-section {
        padding: 8px 16px;
    }
}
