.genre-card {
    align-items: center;
    background-color: var(--variable-collection-primary-color-20);
    border: 1px solid;
    border-color: var(--variable-collection-primary-color-95);
    border-radius: 8px;
    display: inline-flex;
    justify-content: center;
    overflow: hidden;
    padding: 8px 4px;
    margin-top: 16px;
}

.genre-card .text-section {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    padding: 0px 8px;
}

.genre-card .card-text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.genre-card .close-section {
    align-items: center;
    display: inline-flex;
    justify-content: flex-end;
    padding: 0px 8px;
}

.genre-card .close-section .close-button > svg > path {
    fill: var(--variable-collection-primary-color-95);
}

.genre-card .close-section .close-button:hover > svg > path {
    fill: var(--variable-collection-primary-color-60);
}

.genre-card:hover {
    border-color: var(--variable-collection-primary-color-60);
}