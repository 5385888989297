.claim-box {
  align-items: center;
  border: 2px solid;
  border-color: var(--variable-collection-secondary-color-50);
  border-radius: 16px;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  overflow: hidden;
  padding: 32px;
  width: 100%;
  max-width: 400px;
}

.claim-box .form {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.claim-box .div {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.claim-box .input-label {
  color: var(--variable-collection-primary-color-95);
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
}

.claim-box .text-box-wrapper {
  color: var(--variable-collection-primary-color-60);
  background-color: var(--variable-collection-primary-color-20);
  border: 1px solid;
  border-color: var(--variable-collection-primary-color-35);
  border-radius: 8px;
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  height: 48px;
  padding: 8px 16px;
  width: 100%;
}

.claim-box .text-box-wrapper:hover {
  border-color: var(--variable-collection-primary-color-60);
}

.claim-box .text-box-wrapper:invalid {
  border-color: var(--variable-collection-warning-color-50);
}

.claim-box .text-box-wrapper.invalid {
  border-color: var(--variable-collection-warning-color-50);
}

.claim-box .text-box-wrapper:focus {
  outline: none;
}

.claim-box .separate-section {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
}

.claim-box .default {
  color: var(--variable-collection-primary-color-60);
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  text-align: center;
  cursor: pointer;
}

.claim-box .default:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 380px) {
  .claim-box .text-box-wrapper {
      height: 40px;
  }
}

/* @media only screen and (max-width: 420px) {
  .claim-box .separate-section {
      width: 238px;
  }
} */