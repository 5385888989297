@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

:root {
  --h1-primary-font-family: Poppins, Helvetica;
  --h1-primary-font-size: 90px;
  --h1-primary-font-style: normal;
  --h1-primary-font-weight: 400;
  --h1-primary-letter-spacing: 0px;
  --h1-primary-line-height: 115%;

  --h2-primary-font-family: Poppins, Helvetica;
  --h2-primary-font-size: 64px;
  --h2-primary-font-style: normal;
  --h2-primary-font-weight: 400;
  --h2-primary-letter-spacing: 0px;
  --h2-primary-line-height: 100%;

  --h3-primary-font-family: Poppins, Helvetica;
  --h3-primary-font-size: 45px;
  --h3-primary-font-style: normal;
  --h3-primary-font-weight: 400;
  --h3-primary-letter-spacing: 0px;
  --h3-primary-line-height: 100%;

  --h4-primary-font-family: Poppins, Helvetica;
  --h4-primary-font-size: 32px;
  --h4-primary-font-style: normal;
  --h4-primary-font-weight: 400;
  --h4-primary-letter-spacing: 0px;
  --h4-primary-line-height: 100%;

  --h5-primary-font-family: Poppins, Helvetica;
  --h5-primary-font-size: 23px;
  --h5-primary-font-style: normal;
  --h5-primary-font-weight: 400;
  --h5-primary-letter-spacing: 0px;
  --h5-primary-line-height: 130%;

  --h6-primary-font-family: Poppins, Helvetica;
  --h6-primary-font-size: 16px;
  --h6-primary-font-style: normal;
  --h6-primary-font-weight: 300;
  --h6-primary-letter-spacing: 0.24px;
  --h6-primary-line-height: 125%;

  --p-primary-font-family: Poppins, Helvetica;
  --p-primary-font-size: 12px;
  --p-primary-font-style: normal;
  --p-primary-font-weight: 300;
  --p-primary-letter-spacing: 0.24px;
  --p-primary-line-height: 125%;

  --variable-collection-primary-color-5: hsl(233, 36%, 5%);
  --variable-collection-primary-color-5-75: hsla(233, 36%, 5%, 0.75);
  --variable-collection-primary-color-10: hsl(234, 37%, 10%);
  --variable-collection-primary-color-15: hsl(238, 35%, 15%);
  --variable-collection-primary-color-15-15: hsla(238, 35%, 15%, 0.15);
  --variable-collection-primary-color-20: hsl(238, 35%, 20%);
  --variable-collection-primary-color-25: hsl(239, 32%, 25%);
  --variable-collection-primary-color-25-3: hsla(239, 32%, 25%, 0.3);
  --variable-collection-primary-color-25-5: hsla(239, 32%, 25%, 0.5);
  --variable-collection-primary-color-25-9: hsla(239, 32%, 25%, 0.9);
  --variable-collection-primary-color-30: hsl(239, 37%, 30%);
  --variable-collection-primary-color-35: hsl(239, 37%, 35%);
  --variable-collection-primary-color-60: hsl(0, 0%, 60%);
  --variable-collection-primary-color-80: hsl(0, 0%, 80%);
  --variable-collection-primary-color-95: hsl(0, 0%, 95%);

  --variable-collection-secondary-color-30: hsl(172, 66%, 30%);
  --variable-collection-secondary-color-40: hsl(172, 66%, 40%);
  --variable-collection-secondary-color-50: hsl(173, 65%, 50%);
  --variable-collection-secondary-color-60: hsl(173, 57%, 60%);
  --variable-collection-secondary-color-65: hsl(173, 50%, 65%);
  --variable-collection-secondary-color-75: hsl(172, 66%, 75%);
  
  --variable-collection-warning-color-40: hsl(35, 100%, 40%);
  --variable-collection-warning-color-50: hsl(35, 100%, 50%);
  --variable-collection-warning-color-60: hsl(35, 100%, 60%);
  --variable-collection-warning-color-70: hsl(35, 100%, 70%);

  --variable-collection-approve-color-40: hsl(109, 57%, 40%);
  --variable-collection-approve-color-60: hsl(109, 57%, 60%);
  --variable-collection-error-color-60: hsl(0, 100%, 63%);
  --variable-collection-contrast-color: hsl(0, 0%, 100%);
  --variable-collection-contrast-color-5: hsla(0, 0%, 100%, 0.5);

  --variable-collection-background-section-color: hsl(238, 35%, 20%);
  --variable-collection-background-section-color-80: hsla(238, 35%, 20%, 0.8);
  --variable-collection-background-section-color-70: hsla(238, 35%, 20%, 0.7);
  --variable-collection-background-section-color-90: hsla(238, 35%, 20%, 0.9);
  --variable-collection-section-stroke-color: hsl(239, 32%, 25%);
  --variable-collection-background-overlay-color: hsl(239, 32%, 25%);

  --variable-collection-2-background-gradient: linear-gradient(180deg, var(--variable-collection-primary-color-20) 0%, var(--variable-collection-primary-color-5) 93%);
  --variable-collection-2-progress-gradient: linear-gradient(180deg, var(--variable-collection-approve-color-60) 0%, var(--variable-collection-secondary-color-60) 100%);

  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: var(--variable-collection-primary-color-10);
}

::-webkit-scrollbar-thumb {
  background: var(--variable-collection-primary-color-30);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--variable-collection-primary-color-35);
}