.minus-button {
    all: unset;
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.minus-button.disabled {
    cursor: not-allowed;
}

.minus-button:active {
    opacity: 0.5;
}

.minus-button.disabled > svg > path {
    opacity: 0.5;
}

.minus-button > svg > path {
    fill: var(--variable-collection-primary-color-95);
}
