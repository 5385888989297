.send-button {
    all: unset;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    cursor: pointer;
    background-color: var(--variable-collection-secondary-color-65);
    border-radius: 8px;
    transition: all 200ms ease-in-out;
}

.send-button > svg > path {
    fill: var(--variable-collection-primary-color-15);
}

.send-button:hover {
    opacity: 90%;
}

.send-button:active {
    opacity: 50%;
}

.send-button.disabled {
    opacity: 50%;
    cursor: auto;
}