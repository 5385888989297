.user-verification-box {
    align-items: flex-start;
    background-color: var(--variable-collection-background-section-color);
    border: 1px solid;
    border-color: var(--variable-collection-section-stroke-color);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: hidden;
    padding: 8px 16px;
    width: 100%;
}

.user-verification-box .top-box {
    width: 100%;
    border-spacing: 0 8px;
}

.user-verification-box .empty-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 16px;
    width: 100%;
}

.user-verification-box .title {
    text-align: start;
    color: var(--variable-collection-primary-color-95);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
}

.user-verification-box .column-label {
    text-align: start;
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.user-verification-box .value-wrapper {
    text-align: start;
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    cursor: pointer;
}

.user-verification-box .value-wrapper:hover {
    text-decoration: underline;
}

.user-verification-box .subtitle {
    text-align: center;
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
}

.user-verification-box .index {
    display: inline-flex;
    width: 16px;
    justify-content: center;
    color: var(--variable-collection-secondary-color-50);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.user-verification-box .button-wrapper {
    display: flex;
    align-items: center;
}

.user-verification-box .popup-menu-button > svg {
    height: 24px;
    width: 24px;
}

.user-verification-box .tool-tip-style {
    background-color: var(--variable-collection-primary-color-10);
    border: 2px solid var(--variable-collection-primary-color-5);
    border-radius: 8px;
}

.user-verification-box .tip-text-wrapper {
    align-self: stretch;
    color: var(--variable-collection-contrast-color);
    font-family: var(--p-primary-font-family);
    font-size: var(--p-primary-font-size);
    font-style: var(--p-primary-font-style);
    font-weight: var(--p-primary-font-style);
    letter-spacing: var(--p-primary-letter-spacing);
    line-height: var(--p-primary-line-height);
    text-align: center;
}

@media only screen and (max-width: 380px) {
    .user-verification-box .subtitle {
        font-family: var(--h6-primary-font-family);
        font-size: var(--h6-primary-font-size);
        font-style: var(--h6-primary-font-style);
        font-weight: var(--h6-primary-font-weight);
        letter-spacing: var(--h6-primary-letter-spacing);
    }

    .user-verification-box .empty-box > svg {
        width: 60px;
        height: 60px;
    }
}