.sign-up-box {
    align-items: center;
    border: 2px solid;
    border-color: var(--variable-collection-secondary-color-50);
    border-radius: 16px;
    display: inline-flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    padding: 32px;
    width: 100%;
}

.sign-up-box .form {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    width: 100%;
}

.sign-up-box .div {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.sign-up-box .input-label {
    color: var(--variable-collection-primary-color-95);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.sign-up-box .text-box-wrapper {
    color: var(--variable-collection-primary-color-60);
    background-color: var(--variable-collection-primary-color-15);
    border: 1px solid;
    border-color: var(--variable-collection-primary-color-35);
    border-radius: 8px;
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    height: 48px;
    padding: 8px 16px;
    width: 100%;
}

.sign-up-box .text-box-wrapper:hover {
    border-color: var(--variable-collection-secondary-color-40);
}

.sign-up-box .text-box-wrapper:invalid {
    border-color: var(--variable-collection-warning-color-50);
}

.sign-up-box .text-box-wrapper.invalid {
    border-color: var(--variable-collection-warning-color-50);
}

.sign-up-box .text-box-wrapper.invalid:hover {
    border-color: var(--variable-collection-secondary-color-40);
}

.sign-up-box .text-box-wrapper:focus {
    outline: none;
}

.sign-up-box .alternative {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sign-up-box .alternative .default {
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    text-align: center;
}

.sign-up-box .alternative .alert {
    color: var(--variable-collection-warning-color-50);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: 500;
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    text-align: center;
}

.sign-up-box .alternative .link {
    all: unset;
    box-sizing: border-box;
    color: var(--variable-collection-contrast-color);
    cursor: pointer;
}

.sign-up-box .alternative .link:hover {
    text-decoration: underline;
}

.sign-up-box .alternative .previous {
    color: var(--variable-collection-primary-color-60);
    cursor: pointer;
}

.sign-up-box .alternative .previous:hover {
    text-decoration: underline;
}


.sign-up-box .dropdown-box .control:hover {
    border: 1px solid var(--variable-collection-secondary-color-40);
}

.sign-up-box .dropdown-box .active-control {
    border-top: 1px solid var(--variable-collection-secondary-color-40);
    border-left: 1px solid var(--variable-collection-secondary-color-40);
    border-right: 1px solid var(--variable-collection-secondary-color-40);
}

.sign-up-box .dropdown-box .menu {
    border-right: 1px solid var(--variable-collection-secondary-color-40);
    border-bottom: 1px solid var(--variable-collection-secondary-color-40);
    border-left: 1px solid var(--variable-collection-secondary-color-40);
}

@media only screen and (max-width: 400px) {
    .sign-up-box {
        gap: 16px;
        padding: 24px;
    }
}

@media only screen and (max-width: 380px) {
    .sign-up-box .text-box-wrapper {
        height: 40px;
    }
    .sign-up-box .alternative .alert {
        color: var(--variable-collection-warning-color-50);
        font-family: var(--p-primary-font-family);
        font-size: var(--p-primary-font-size);
        font-style: var(--p-primary-font-style);
        font-weight: 500;
        letter-spacing: var(--p-primary-letter-spacing);
        line-height: var(--p-primary-line-height);
        text-align: center;
    }
}