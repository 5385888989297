.artist-track-stat-card {
    align-items: center;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 8px 16px;
    width: 100%;
}

.artist-track-stat-card .card-left-section {
    align-items: center;
    display: flex;
    gap: 16px;
    width: 100%;
    padding: 0px 32px 0px 0px;
}

.artist-track-stat-card .track-image {
    border-radius: 4px;
    height: 56px;
    width: 56px;
    object-fit: cover;
}

.artist-track-stat-card .main-info {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    padding: 4px 0px;
    min-width: 100px;
}

.artist-track-stat-card .track-name {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    color: var(--variable-collection-contrast-color);
    display: -webkit-box;
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
}

.artist-track-stat-card .album-name {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    color: var(--variable-collection-contrast-color);
    display: -webkit-box;
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: 135%;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
}

.artist-track-stat-card .card-right-section {
    align-items: center;
    display: inline-flex;
    justify-content: space-between;
}

.artist-track-stat-card .stat-section {
    align-items: center;
    display: inline-flex;
    gap: 16px;
}

.artist-track-stat-card .value-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.artist-track-stat-card .change-section {
    align-items: center;
    display: inline-flex;
    gap: 16px;
}

.artist-track-stat-card .change-value {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    width: 56px;
}

.artist-track-stat-card.hover {
    background-color: var(--variable-collection-primary-color-30);
}

.artist-track-stat-card > svg {
    overflow: unset;
}

.artist-track-stat-card .change-section.positive > svg > path {
    color: var(--variable-collection-approve-color-60);
}

.artist-track-stat-card .change-section.negative > svg > path {
    color: var(--variable-collection-error-color-60);
}
