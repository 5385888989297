.primary-button {
    all: unset;
    box-sizing: border-box;
    user-select: none;
    align-items: center;
    border-radius: 8px;
    display: flex;
    min-height: 48px;
    justify-content: center;
    padding: 8px 8px;
    width: 100%;
    gap: 8px;
    box-sizing: border-box;
    transition: all 200ms ease-in-out;
    cursor: pointer;
}

.primary-button.filled {
    background-color: var(--variable-collection-secondary-color-75);
}

.primary-button.unfilled {
    border: 2px solid var(--variable-collection-secondary-color-50);
}

.primary-button > svg > path {
    color: var(--variable-collection-primary-color-20);
}

.primary-button .button-text-wrapper {
    display: flex;
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-weight: 500;
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    text-align: center;
}

.primary-button.filled .button-text-wrapper {
    color: var(--variable-collection-primary-color-20);
}

.primary-button.unfilled .button-text-wrapper {
    color: var(--variable-collection-contrast-color);
}

.primary-button.outline:disabled, .primary-button.outline[disabled] {
    background-color: transparent;
    border: 2px solid;
    border-color: var(--variable-collection-primary-color-20);
    color: var(--variable-collection-primary-color-35);
    pointer-events: none;
}

.primary-button.outline:disabled .button-text-wrapper, .primary-button.outline[disabled] .button-text-wrapper {
    color: var(--variable-collection-primary-color-35);
}

.primary-button.info:disabled, .primary-button.info[disabled] {
    background-color: var(--variable-collection-primary-color-20);
    border-color: var(--variable-collection-primary-color-20);
    pointer-events: none;
}

.primary-button.info:disabled .button-text-wrapper, .primary-button.info[disabled] .button-text-wrapper {
    color: var(--variable-collection-primary-color-60);
}

.primary-button.fade:disabled, .primary-button.fade[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

.primary-button:active {
    opacity: 50%;
}

.primary-button.filled:hover {
    background-color: var(--variable-collection-secondary-color-65);
}

.primary-button.unfilled:hover {
    border-color: var(--variable-collection-primary-color-35);
}

@media only screen and (max-width: 380px) {
    .primary-button .button-text-wrapper {
        font-family: var(--h6-primary-font-family);
        font-size: var(--h6-primary-font-size);
        font-style: var(--h6-primary-font-style);
        letter-spacing: var(--h6-primary-letter-spacing);
        line-height: var(--h6-primary-line-height);
    }
    .primary-button {
        min-height: 40px;
    }
    .primary-button > svg {
        width: 24px;
        height: 24px;
    }
}