.update-username-frame {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    padding: 16px 16px;
    justify-content: center;
    width: 100%;
}

.update-username-frame .update-username-section {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    padding: 16px 16px;
    width: 100%;
    max-width: 420px;
}

.update-username-frame .header {
    align-items: center;
    background-color: transparent;
    display: inline-flex;
}

.update-username-frame .title {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h3-primary-font-family);
    font-size: var(--h3-primary-font-size);
    font-style: var(--h3-primary-font-style);
    font-weight: var(--h3-primary-font-weight);
    letter-spacing: var(--h3-primary-letter-spacing);
    line-height: var(--h3-primary-line-height);
    text-align: center;
}

@media only screen and (max-width: 400px) {
    .update-username-frame {
        gap: 16px;
    }

    .update-username-frame .update-username-section {
        gap: 16px;
    }
    
    .update-username-frame .title {
        font-family: var(--h4-primary-font-family);
        font-size: var(--h4-primary-font-size);
        font-style: var(--h4-primary-font-style);
        font-weight: var(--h4-primary-font-weight);
        letter-spacing: var(--h4-primary-letter-spacing);
        line-height: var(--h4-primary-line-height);
    }
}
