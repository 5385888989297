.announcement-card {
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 16px;
  justify-content: space-around;
  overflow: hidden;
  padding: 8px 16px 8px 0px;
  width: 100%;
  margin-bottom: 8px;
  user-select: none;
}

.announcement-card .left-section {
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 16px;
  padding: 0px 8px 0px 0px;
}

.announcement-card .track-image {
  height: 72px;
  width: 72px;
  object-fit: cover;
  border-radius: 4px;
}

.announcement-card .main-info {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.announcement-card .right-section {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 16px;
  width: 100%;
}

.announcement-card .brief {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--variable-collection-primary-color-60);
  display: -webkit-box;
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
}

.announcement-card .frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 51.5px;
  justify-content: flex-end;
  width: 100%;
}

.announcement-card .title-text {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: var(--variable-collection-contrast-color);
  display: -webkit-box;
  font-family: var(--h5-primary-font-family);
  font-size: var(--h5-primary-font-size);
  font-style: var(--h5-primary-font-style);
  font-weight: var(--h5-primary-font-weight);
  letter-spacing: var(--h5-primary-letter-spacing);
  line-height: var(--h5-primary-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
}

.announcement-card .description-text {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: var(--variable-collection-primary-color-95);
  display: -webkit-box;
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
}

.announcement-card:hover {
  background-color: var(--variable-collection-primary-color-30);
}