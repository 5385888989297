.trend-card {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.trend-card .title {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.trend-card .info {
    align-items: center;
    display: inline-flex;
    gap: 4px;
}

.trend-card .text-wrapper {
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.trend-card .hash-tag {
    color: var(--variable-collection-secondary-color-75);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    width: fit-content;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
}