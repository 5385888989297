.back-button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    width: 32px;
    height: 32px;
    transition: all 200ms ease-in-out;
}

.back-button > svg > path {
    fill: var(--variable-collection-contrast-color);
}

.back-button:hover {
    opacity: 90%;
}

.back-button:active {
    opacity: 50%;
}