.info-double-card {
    align-items: flex-start;
    background-color: var(--variable-collection-primary-color-15);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    width: 100%;
}

.info-double-card .top-card {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--variable-collection-secondary-color-65);
    border-radius: 4px 4px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    overflow: hidden;
    padding: 8px 16px;
    width: 100%;
}

.info-double-card .top-title {
    color: var(--variable-collection-primary-color-15);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.info-double-card .top-subtitle {
    color: var(--variable-collection-primary-color-15);
    font-family: var(--h4-primary-font-family);
    font-size: var(--h4-primary-font-size);
    font-style: var(--h4-primary-font-style);
    font-weight: var(--h4-primary-font-weight);
    letter-spacing: var(--h4-primary-letter-spacing);
    line-height: var(--h4-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.info-double-card .bottom-card {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    padding: 8px 16px;
    width: 100%;
}

.info-double-card .bottom-title {
    align-self: stretch;
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
}

.info-double-card .bottom-subtitle {
    align-self: stretch;
    color: var(--variable-collection-contrast-color);
    font-family: var(--h4-primary-font-family);
    font-size: var(--h4-primary-font-size);
    font-style: var(--h4-primary-font-style);
    font-weight: var(--h4-primary-font-weight);
    letter-spacing: var(--h4-primary-letter-spacing);
    line-height: var(--h4-primary-line-height);
}