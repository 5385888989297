.public-post-page {
    display: flex;
    background: var(--variable-collection-2-background-gradient);
    background-color: var(--variable-collection-primary-color-20);
    width: 100%;
    max-width: 100vw;
    min-height: 100vh;
    min-height: 100svh;
    height: 100%;
    padding: 16px;
    gap: 16px;
    justify-content: center;
}

.public-post-page .post-page-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    flex: 1;
    align-self: stretch;
    width: 100%;
    height: 100%;
}

.public-post-page .post-page-section.left {
    max-width: 648px;
}

.public-post-page .post-page-section.right {
    max-width: 440px;
}

.public-post-page .post-page-section.vertical {
    max-width: 560px;
}