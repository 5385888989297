.streaming-connect {
  align-items: center;
  background-color: var(--variable-collection-primary-color-25-5);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  overflow: hidden;
  padding: 32px;
  width: 100%;
  align-self: stretch;
}

.streaming-connect>svg>path {
  color: var(--variable-collection-primary-color-95);
}

.streaming-connect .warning {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  width: 100%;
}

.streaming-connect .title {
  color: var(--variable-collection-contrast-color);
  font-family: var(--h4-primary-font-family);
  font-size: var(--h4-primary-font-size);
  font-style: var(--h4-primary-font-style);
  font-weight: var(--h4-primary-font-weight);
  letter-spacing: var(--h4-primary-letter-spacing);
  line-height: var(--h4-primary-line-height);
  text-align: center;
  white-space: nowrap;
  width: fit-content;

}

.streaming-connect .subtitle {
  align-self: stretch;
  color: var(--variable-collection-contrast-color);
  font-family: var(--h5-primary-font-family);
  font-size: var(--h5-primary-font-size);
  font-style: var(--h5-primary-font-style);
  font-weight: var(--h5-primary-font-weight);
  letter-spacing: var(--h5-primary-letter-spacing);
  line-height: var(--h5-primary-line-height);
  text-align: center;
}

.streaming-connect .note {
  align-self: stretch;
  color: var(--variable-collection-primary-color-60);
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  text-align: center;
}

.streaming-connect .primary-button {
  max-width: 304px;
}

.streaming-connect .spotify-email-confirmation-box {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  max-width: 440px;
  width: 100%;
}

.streaming-connect .spotify-email-box {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.streaming-connect .input-label {
  color: var(--variable-collection-primary-color-95);
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
}

.streaming-connect .text-box-wrapper {
  color: var(--variable-collection-primary-color-60);
  background-color: var(--variable-collection-primary-color-20);
  border: 1px solid;
  border-color: var(--variable-collection-primary-color-35);
  border-radius: 8px;
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  height: 48px;
  padding: 8px 16px;
  width: 100%;
}

.streaming-connect .text-box-wrapper:hover {
  border-color: var(--variable-collection-primary-color-60);
}

.streaming-connect .text-box-wrapper:invalid {
  border-color: var(--variable-collection-warning-color-50);
}

.streaming-connect .text-box-wrapper.invalid {
  border-color: var(--variable-collection-warning-color-50);
}

.streaming-connect .text-box-wrapper:focus {
  outline: none;
}

@media only screen and (max-width: 460px) {
  .streaming-connect {
    height: 100%;
  }
}

@media only screen and (max-width: 380px) {
  .streaming-connect>svg {
    width: 60px;
    height: 60px;
  }

  .streaming-connect .title {
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
  }

  .streaming-connect .subtitle {
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
  }

  .streaming-connect .text-box-wrapper {
    height: 40px;
  }

  .streaming-connect .spotify-email-confirmation-box {
    gap: 8px;
  }
}