.char-counter {
    align-items: flex-start;
    background-color: var(--variable-collection-primary-color-15);
    border-radius: 8px;
    display: flex;
    height: 3px;
    width: 100%;
}

.char-counter .progress {
    align-self: stretch;
    background-color: var(--variable-collection-secondary-color-75);
    border-radius: 8px 0px 0px 8px;
    height: 3px;
    width: 0px;
}

.char-counter .progress.limit {
    background-color: var(--variable-collection-warning-color-60) !important;
}