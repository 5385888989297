.share-post {
    align-items: center;
    background-color: var(--variable-collection-primary-color-20);
    border: 1px solid;
    border-radius: 16px;
    border-color: var(--variable-collection-primary-color-35);
    display: flex;
    flex-direction: column;
}

.share-post .option-section {
    align-items: flex-start;
    align-self: stretch;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--variable-collection-primary-color-25);
    display: flex;
    width: 100%;
}

.share-post .option {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 8px;
    justify-content: center;
    padding: 16px;
    cursor: pointer;
}

.share-post .option:active {
    opacity: 0.7;
}

.share-post .option.left {
    border-color: var(--variable-collection-primary-color-25);
    border-right-style: solid;
    border-right-width: 1px;
    border-top-left-radius: 16px;
}

.share-post .option.right {
    border-top-right-radius: 16px;
}

.share-post .option.copy {
    min-width: 142px;
}

.share-post .text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.share-post .option.hightlighted .text-wrapper,
.share-post .option.hightlighted > svg > path {
    color: var(--variable-collection-secondary-color-75);
    fill: var(--variable-collection-secondary-color-75);
}

.share-post .social-media {
    display: flex;
    width: 100%;
}

@media only screen and (max-width: 380px) {
    .share-post .option-section {
        flex-direction: column;
    }

    .share-post .option {
        width: 100%;
    }

    .share-post .option.left {
        border-right-style: unset;
        border-right-width: unset;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-top-right-radius: 16px;
    }
    
    .share-post .option.right {
        border-top-right-radius: 16px;
    }
}  