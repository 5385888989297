.general-stats-box {
  align-items: flex-start;
  background-color: var(--variable-collection-background-section-color);
  border: 1px solid;
  border-color: var(--variable-collection-section-stroke-color);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.general-stats-box .top-section {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
}

.general-stats-box .title-section {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 8px 8px;
}

.general-stats-box .title-section.compact {
  flex-direction: row;
  align-items: baseline;
}


.general-stats-box .title {
  color: var(--variable-collection-contrast-color);
  font-family: var(--h4-primary-font-family);
  font-size: var(--h4-primary-font-size);
  font-style: var(--h4-primary-font-style);
  font-weight: var(--h4-primary-font-weight);
  letter-spacing: var(--h4-primary-letter-spacing);
  line-height: var(--h4-primary-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.general-stats-box .title.medium {
  color: var(--variable-collection-contrast-color);
  font-family: var(--h5-primary-font-family);
  font-size: var(--h5-primary-font-size);
  font-style: var(--h5-primary-font-style);
  font-weight: var(--h5-primary-font-weight);
  letter-spacing: var(--h5-primary-letter-spacing);
  line-height: var(--h5-primary-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.general-stats-box .title.compact {
  color: var(--variable-collection-contrast-color);
  font-family: var(--h5-primary-font-family);
  font-size: var(--h5-primary-font-size);
  font-style: var(--h5-primary-font-style);
  font-weight: var(--h5-primary-font-weight);
  letter-spacing: var(--h5-primary-letter-spacing);
  line-height: var(--h5-primary-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.general-stats-box .subtitle {
  color: var(--variable-collection-primary-color-60);
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.general-stats-box .tabs {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 152px;
  padding: 16px 0px;
  width: 100%;
}

.general-stats-box .chart {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  width: 100%;
}

.general-stats-box .dropdown-box {
  max-width: 200px;
}

.general-stats-box .loader-box {
  height: 100%;
}

@media only screen and (max-width: 420px) {
  .general-stats-box .title {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .general-stats-box .title.compact {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
}

@media only screen and (max-width: 380px) {
  .general-stats-box .dropdown-box .text-wrapper  {
    color: var(--variable-collection-primary-color-60);
    font-family: var(--p-primary-font-family);
    font-size: var(--p-primary-font-size);
    font-style: var(--p-primary-font-style);
    font-weight: var(--p-primary-font-weight);
    letter-spacing: var(--p-primary-letter-spacing);
    line-height: var(--p-primary-line-height);
  }
}