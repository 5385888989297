.stat-tab {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    padding: 16px;
    width: 100%;
    cursor: pointer;
}

.stat-tab .label {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.stat-tab .value-text {
    color: var(--variable-collection-contrast-color);
    font-family: "Poppins-Medium", Helvetica;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
    white-space: nowrap;
    width: fit-content;
}

.stat-tab.active {
    background-color: var(--variable-collection-primary-color-15);
}

.stat-tab.hover {
    background-color: var(--variable-collection-primary-color-30);
}