.report-post-overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    align-items: flex-start;
    background-color: var(--variable-collection-background-overlay-color);
    border: 2px solid;
    border-color: var(--variable-collection-primary-color-20);
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    gap: 16px;
    padding: 16px;
    overflow: hidden;
    width: 640px;
    height: 560px;
    z-index: 1000;
}

.report-post-overlay .reasons {
    align-items: flex-start;
    align-self: stretch;
    border: 1px solid;
    border-color: var(--variable-collection-primary-color-10);
    border-radius: 8px;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    overflow: auto;
    padding: 16px;
    width: 100%;
}

.report-post-overlay .div {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.report-post-overlay .primary-button-with {
    all: unset;
    align-items: center;
    background-color: var(--variable-collection-secondary-color-75);
    border-radius: 8px;
    box-sizing: border-box;
    display: inline-flex;
    gap: 8px;
    justify-content: center;
    padding: 8px 16px;
}

.report-post-overlay .text-wrapper {
    color: var(--variable-collection-primary-color-20);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.report-post-overlay .primary-button-with-2 {
    align-items: center;
    background-color: var(--variable-collection-secondary-color-75);
    border-radius: 8px;
    display: inline-flex;
    gap: 8px;
    justify-content: center;
    padding: 8px 16px;
}

.report-post-overlay .primary-button {
    width: auto;
    padding: 12px 16px;
    min-height: auto;
}

.report-post-overlay .primary-button > svg {
    width: 20px;
    height: 20px;
}

.report-post-overlay .primary-button .button-text-wrapper {
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: 500;
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.report-post-overlay .reasons::-webkit-scrollbar {
    width: 16px;
}

.report-post-overlay .reasons::-webkit-scrollbar-track {
    background: transparent;
}

.report-post-overlay .reasons::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: inset 0 0 16px var(--variable-collection-primary-color-30);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
}

.report-post-overlay .reasons::-webkit-scrollbar-thumb:hover {
    background: transparent;
    box-shadow: inset 0 0 16px var(--variable-collection-primary-color-35);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
}

@media only screen and (min-width: 617px) and (max-width: 692px) {
    .report-post-overlay {
        width: 440px;
    }

    .report-post-overlay .reason-card .description {
        padding: 0px 64px 0px 0px;
    }
}

@media only screen and (min-width: 461px) and (max-width: 616px) {
    .report-post-overlay {
        width: 375px;
    }

    .report-post-overlay .reason-card .description {
        padding: 0px 64px 0px 0px;
    }
}

@media only screen and (min-width: 381px) and (max-width: 460px) {
    .report-post-overlay {
        width: 350px;
    }

    .report-post-overlay .reason-card .description {
        padding: 0px 32px 0px 0px;
    }
}

@media only screen and (max-width: 380px) {
    .report-post-overlay {
        width: 290px;
    }

    .report-post-overlay .reason-card .description {
        padding: 0px 32px 0px 0px;
    }
}