.claim-artist-card-component {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    width: 100%;
}


.claim-artist-card-component .card-section {
    align-items: center;
    display: flex;
    gap: 16px;
    padding: 0px 8px 0px 0px;
    width: 100%;
    overflow: hidden;
}

.claim-artist-card-component .artist-image {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    object-fit: cover;
}

.claim-artist-card-component .artist-name {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@media only screen and (max-width: 350px) {
    .claim-artist-card-component .artist-name {
        width: 130px;
    }
}

@media only screen and (min-width: 351px) and (max-width: 590px) {
    .claim-artist-card-component .artist-name {
        width: 150px;
    }
}