.settings-menu {
    align-items: center;
    background-color: var(--variable-collection-background-section-color);
    border: 2px solid;
    border-color: var(--variable-collection-secondary-color-50);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    align-self: stretch;
    width: 320px;
    max-width: 320px;
}

.settings-menu .stats-menu {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
}

.settings-menu .side-menu-button {
    width: 100%;
}