.close-button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
}

.close-button > svg > path {
    fill: var(--variable-collection-primary-color-20);
}

.close-button:hover > svg > path {
    fill: var(--variable-collection-primary-color-95);
}