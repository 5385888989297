.notification-card {
  align-items: flex-start;
  display: flex;
  gap: 16px;
  width: 100%;
  padding-left: 16px;
  border-left: 2px solid var(--variable-collection-secondary-color-50);
  cursor: pointer;
}

.notification-card .left-section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
}

.notification-card .title {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: var(--variable-collection-contrast-color);
  display: -webkit-box;
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
}

.notification-card .subtitle {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: var(--variable-collection-primary-color-60);
  display: -webkit-box;
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
}

.notification-card .subtitle-full {
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
  }

.notification-card .date-section {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
}

.notification-card .date-text {
  color: var(--variable-collection-secondary-color-50);
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  white-space: nowrap;
  width: fit-content;
}