.sigmund-rate-card-bucket {
  align-items: center;
  justify-content: space-between;
  display: flex;
  gap: 16px;
  width: 100%;
}

.sigmund-rate-card-bucket .rate-section {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: flex-end;
}

.sigmund-rate-card-bucket .rate-text {
  color: var(--variable-collection-contrast-color);
  font-family: var(--h3-primary-font-family);
  font-size: var(--h3-primary-font-size);
  font-style: var(--h3-primary-font-style);
  font-weight: var(--h3-primary-font-weight);
  letter-spacing: var(--h3-primary-letter-spacing);
  line-height: var(--h3-primary-line-height);
  white-space: nowrap;
  width: fit-content;
}

.sigmund-rate-card-bucket .label {
  color: var(--variable-collection-contrast-color);
  font-family: var(--h5-primary-font-family);
  font-size: var(--h5-primary-font-size);
  font-style: var(--h5-primary-font-style);
  font-weight: var(--h5-primary-font-weight);
  letter-spacing: var(--h5-primary-letter-spacing);
  line-height: var(--h5-primary-line-height);
  white-space: nowrap;
  width: fit-content;
}

@media only screen and (max-width: 380px) {
  .sigmund-rate-card-bucket .label {
      font-family: var(--h6-primary-font-family);
      font-size: var(--h6-primary-font-size);
      font-style: var(--h6-primary-font-style);
      font-weight: var(--h6-primary-font-weight);
      letter-spacing: var(--h6-primary-letter-spacing);
      line-height: var(--h6-primary-line-height);
  }
}