.reset-password-frame {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    justify-content: center;
    width: 100%;
    padding: 16px 16px;
}

.reset-password-frame .reset-password-section {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    padding: 16px 16px;
    max-width: 420px;
    width: 100%;
}

.reset-password-frame .header {
    align-items: center;
    background-color: transparent;
    display: inline-flex;
}

.reset-password-frame .title {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h3-primary-font-family);
    font-size: var(--h3-primary-font-size);
    font-style: var(--h3-primary-font-style);
    font-weight: var(--h3-primary-font-weight);
    letter-spacing: var(--h3-primary-letter-spacing);
    line-height: var(--h3-primary-line-height);
    text-align: center;
}

.password-reset-box {
    align-items: center;
    border: 2px solid;
    border-color: var(--variable-collection-secondary-color-50);
    border-radius: 16px;
    display: inline-flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    padding: 32px;
    width: 100%;
}

.password-reset-box .form {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    width: 100%;
}

.password-reset-box .div {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.password-reset-box .input-label {
    color: var(--variable-collection-primary-color-95);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.password-reset-box .text-box-wrapper {
    color: var(--variable-collection-primary-color-60);
    background-color: var(--variable-collection-primary-color-15);
    border: 1px solid;
    border-color: var(--variable-collection-primary-color-35);
    border-radius: 8px;
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    height: 48px;
    padding: 8px 16px;
    width: 100%;
}

.password-reset-box .text-box-wrapper:hover {
    border-color: var(--variable-collection-secondary-color-40);
}

.password-reset-box .text-box-wrapper:invalid {
    border-color: var(--variable-collection-warning-color-50);
}

.password-reset-box .text-box-wrapper.invalid {
    border-color: var(--variable-collection-warning-color-50);
}

.password-reset-box .text-box-wrapper:focus {
    outline: none;
}

.password-reset-box .alternative {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.password-reset-box .alternative .default {
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    text-align: center;
}

.password-reset-box .alternative .alert {
    color: var(--variable-collection-warning-color-50);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: 500;
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    text-align: center;
}

.password-reset-box .alternative .link {
    all: unset;
    box-sizing: border-box;
    color: var(--variable-collection-contrast-color);
    cursor: pointer;
}

.password-reset-box .alternative .link:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 400px) {
    .password-reset-box {
        gap: 16px;
        padding: 24px;
    }

    .reset-password-frame .reset-password-section {
        gap: 16px;
    }
}

@media only screen and (max-width: 380px) {
    .password-reset-box .text-box-wrapper {
        height: 40px;
    }

    .password-reset-box .alternative .alert {
        color: var(--variable-collection-warning-color-50);
        font-family: var(--p-primary-font-family);
        font-size: var(--p-primary-font-size);
        font-style: var(--p-primary-font-style);
        font-weight: 500;
        letter-spacing: var(--p-primary-letter-spacing);
        line-height: var(--p-primary-line-height);
        text-align: center;
    }

    .reset-password-frame .title {
        font-family: var(--h4-primary-font-family);
        font-size: var(--h4-primary-font-size);
        font-style: var(--h4-primary-font-style);
        font-weight: var(--h4-primary-font-weight);
        letter-spacing: var(--h4-primary-letter-spacing);
        line-height: var(--h4-primary-line-height);
    }
}