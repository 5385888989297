.track-stats-box {
    align-items: flex-start;
    background-color: var(--variable-collection-background-section-color);
    border: 1px solid;
    border-color: var(--variable-collection-section-stroke-color);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.track-stats-box .top-section {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    gap: 16px;
    width: 100%;
}

.track-stats-box .title-section {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
}

.track-stats-box .title {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h4-primary-font-family);
    font-size: var(--h4-primary-font-size);
    font-style: var(--h4-primary-font-style);
    font-weight: var(--h4-primary-font-weight);
    letter-spacing: var(--h4-primary-letter-spacing);
    line-height: var(--h4-primary-line-height);
    text-align: right;
    white-space: nowrap;
    width: fit-content;
}

.track-stats-box .subtitle {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    text-align: center;
    width: fit-content;
}

.track-stats-box .cards-section {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.track-stats-box .section {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    justify-content: center;
    width: 100%;
    height: 0px;
}

.track-stats-box .track-box {
    align-self: stretch;
    overflow: hidden;
    overflow-y: scroll;
    padding: 8px 0px;
    width: 100%;
}

.track-stats-box hr.separator-line {
    all: unset;
    width: 256px;
    height: 2px;
    color: var(--variable-collection-primary-color-35);
    background-color: var(--variable-collection-primary-color-35);
    border-color: var(--variable-collection-primary-color-35);
}

.track-stats-box .empty {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    justify-content: center;
    padding-left: 16px;
    width: 100%;
    height: 90%;
}

.track-stats-box .end-card {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 4px;
    padding-bottom: 8px;
    padding-left: 16px;
}

.track-stats-box .track-box .primary-button {
    min-height: 32px;
    padding: 4px 12px;
    width: 50%;
    max-width: 320px;
    border-radius: 4px;
}

.track-stats-box .track-box .primary-button .button-text-wrapper {
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.track-stats-box .switch-component {
    max-width: 240px;
}

.track-stats-box ::-webkit-scrollbar {
    width: 16px;
}

.track-stats-box ::-webkit-scrollbar-track {
    background: transparent;
}

.track-stats-box ::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: inset 0 0 16px var(--variable-collection-primary-color-30);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
}

.track-stats-box ::-webkit-scrollbar-thumb:hover {
    background: transparent;
    box-shadow: inset 0 0 16px var(--variable-collection-primary-color-35);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
}

@media only screen and (max-width: 692px) {

    .track-stats-box .artist-track-stat-card,
    .track-stats-box .top-section {
        padding-left: 0px;
    }
}

@media only screen and (max-width: 380px) {
    .track-stats-box .empty>svg {
        width: 60px;
        height: 60px;
    }

    .track-stats-box .artist-track-stat-card .change-section>svg {
        width: 16px;
        height: 16px;
    }

    .track-stats-box .subtitle,
    .track-stats-box .artist-track-stat-card .track-name,
    .track-stats-box .artist-track-stat-card .album-name,
    .track-stats-box .artist-track-stat-card .value-wrapper {
        font-family: var(--h6-primary-font-family);
        font-size: var(--h6-primary-font-size);
        font-style: var(--h6-primary-font-style);
        font-weight: var(--h6-primary-font-weight);
        letter-spacing: var(--h6-primary-letter-spacing);
        line-height: var(--h6-primary-line-height);
    }

    .track-stats-box .artist-track-stat-card .change-value {
        color: var(--variable-collection-contrast-color);
        font-family: var(--p-primary-font-family);
        font-size: var(--p-primary-font-size);
        font-style: var(--p-primary-font-style);
        font-weight: var(--p-primary-font-weight);
        letter-spacing: var(--p-primary-letter-spacing);
        line-height: var(--p-primary-line-height);
    }

    .track-stats-box .artist-track-stat-card .album-name {
        color: var(--variable-collection-primary-color-60);
    }

    .track-stats-box .artist-track-stat-card .track-image {
        width: 48px;
        height: 48px;
    }

    .track-stats-box .artist-track-stat-card .stat-section,
    .track-stats-box .artist-track-stat-card .change-section,
    .track-stats-box .artist-track-stat-card .card-left-section {
        gap: 8px
    }

    .track-stats-box hr.separator-line {
        width: 160px;
    }

}