.side-secondary-menu.desktop {
    align-items: center;
    background-color: var(--variable-collection-background-section-color);
    border: 1px solid;
    border-color: var(--variable-collection-section-stroke-color);
    border-radius: 16px;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    gap: 16px;
    padding: 16px;
}

.side-secondary-menu.desktop .profile-icon {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    user-select: none;
    cursor: pointer;
}

.side-secondary-menu.desktop .profile-icon.default {
    border: 1px solid;
    border-color: var(--variable-collection-primary-color-35);
}

.side-secondary-menu.desktop .profile-icon.disable {
    pointer-events: none;
}

.side-secondary-menu.desktop .profile-icon:hover {
    outline: 2px solid var(--variable-collection-primary-color-95);
}

.side-secondary-menu.desktop>svg>path {
    color: var(--variable-collection-primary-color-60);
}

.side-secondary-menu.desktop>svg:hover>path {
    color: var(--variable-collection-primary-color-95);
}

.side-secondary-menu.mobile {
    align-items: center;
    background-color: var(--variable-collection-background-section-color-90);
    border: 1px solid;
    border-color: var(--variable-collection-section-stroke-color);
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
}

.side-secondary-menu.mobile .center-section {
    align-items: center;
    display: inline-flex;
    gap: 16px;
}

.side-secondary-menu.mobile .profile-icon {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    user-select: none;
}

.side-secondary-menu.desktop .selection-overlay {
    position: absolute;
    right: calc(100% + 12px);
    top: 0;
    z-index: 1000;
}

.side-secondary-menu.mobile .selection-overlay {
    position: absolute;
    right: 0;
    top: calc(100% + 12px);
    z-index: 1000;
}

@media only screen and (max-width: 692px) and (min-width: 617px) {
    .side-secondary-menu.desktop {
        flex-direction: row-reverse;
    }

    .side-secondary-menu.desktop .selection-overlay {
        right: auto;
        left: calc(100% + 12px);
    }
}

@media only screen and (max-width: 330px) {
    .side-secondary-menu.mobile .profile-icon {
        width: 32px;
        height: 32px;
        border-radius: 32px;
    }
}