.console-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    flex: 1;
    align-self: stretch;
    width: 100%;
}


@media only screen and (max-width: 460px) {
    .console-section {
        height: 0px;
    }
}