.popup-menu-button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
}

.popup-menu-button > svg > path {
    fill: var(--variable-collection-primary-color-95);
}

.popup-menu-button:active > svg > path {
    opacity: 0.5;
}