.post-page-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    flex: 1;
    align-self: stretch;
}

.post-page-section.right {
    max-width: 480px;
}


@media only screen and (max-width: 460px) {
    .post-page-section {
        width: 100%;
        height: 0px;
    }
}