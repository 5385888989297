.tokens {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tokens .text-wrapper {
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: 14px;
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.tokens > svg > path {
    fill: var(--variable-collection-primary-color-60);
}