.post-menu {
    align-items: flex-start;
    background-color: var(--variable-collection-primary-color-20);
    border: 1px solid;
    border-radius: 8px;
    border-color: var(--variable-collection-primary-color-35);
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    min-width: 192px;
}

.post-menu .option {
    all: unset;
    box-sizing: border-box;
    align-items: center;
    display: inline-flex;
    gap: 4px;
    cursor: pointer;
}

.post-menu .option:active {
    opacity: 50%;
}

.post-menu .option.danger > svg > path,
.post-menu .option.danger .text-wrapper {
    fill: var(--variable-collection-error-color-60) !important;
    color: var(--variable-collection-error-color-60) !important;
}

.post-menu .option.warning > svg > path,
.post-menu .option.warning .text-wrapper {
    fill: var(--variable-collection-warning-color-60) !important;
    color: var(--variable-collection-warning-color-60) !important;
}

.post-menu .text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}