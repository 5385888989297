.unauth-user-box {
    align-items: flex-start;
    background-color: var(--variable-collection-background-section-color);
    border: 1px solid;
    border-color: var(--variable-collection-section-stroke-color);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: hidden;
    padding: 16px;
    width: 100%;
}

.unauth-user-box .header {
    align-items: flex-start;
    align-self: stretch;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.unauth-user-box .text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.unauth-user-box .div {
    align-self: stretch;
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.unauth-user-box .actions {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    width: 100%;
}

.unauth-user-box .primary-button-v1 {
    width: 100%;
}