.stock-card {
  align-items: center;
  background-color: var(--variable-collection-primary-color-20);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: 4px 8px;
  width: 144px;
  border: 1px solid transparent;
}

.stock-card .value {
  color: var(--variable-collection-contrast-color);
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.stock-card .label {
  color: var(--variable-collection-primary-color-60);
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.stock-card.warning {
  border-color: var(--variable-collection-warning-color-60) !important;
}

.stock-card.warning .label {
  color: var(--variable-collection-warning-color-60) !important;
}

.stock-card.warning .value {
  color: var(--variable-collection-warning-color-60) !important;
}