.stat-card {
  align-items: baseline;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
}

.stat-card .changing-section.positive > svg > path {
    color: var(--variable-collection-approve-color-60);
}

.stat-card .changing-section.negative > svg > path {
    color: var(--variable-collection-error-color-60);
}

.stat-card .left-section {
  white-space: nowrap;
  width: fit-content;
}

.stat-card .primary-value {
  color: var(--variable-collection-contrast-color);
  font-family: var(--h3-primary-font-family);
  font-size: var(--h3-primary-font-size);
  font-style: var(--h3-primary-font-style);
  font-weight: var(--h3-primary-font-weight);
  letter-spacing: var(--h3-primary-letter-spacing);
  line-height: var(--h3-primary-line-height);
}

.stat-card .primary-label {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
}

.stat-card .changing-section {
  align-items: center;
  display: flex;
  gap: 8px;
}

.stat-card .change-value {
  color: var(--variable-collection-contrast-color);
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  white-space: nowrap;
  width: fit-content;
}