.new-post {
    align-items: flex-start;
    background-color: var(--variable-collection-background-section-color);
    border: 1px solid;
    border-color: var(--variable-collection-secondary-color-65);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    width: 100%;
}

.new-post .new-post-preview {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.new-post .div {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 8px;
    width: 100%;
}

.new-post .left-section {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
}

.new-post .profile-icon {
    border-radius: 40px;
    height: 40px;
    width: 40px;
    user-select: none;
}

.new-post .profile-icon.small {
    height: 24px;
    width: 24px;
}

.new-post .profile-icon.default {
    border: 1px solid;
    border-color: var(--variable-collection-primary-color-35);
}

.new-post .right-section {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
}

.new-post .action-section {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 10px;
    width: 100%;
}

.new-post .div-1 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
}

.new-post .div-2 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 4px;
    width: 100%;
}

.new-post .header {
    align-items: flex-start;
    display: flex;
    height: 20px;
    width: 100%;
}

.new-post .div-3 {
    align-items: center;
    display: inline-flex;
    gap: 8px;
}

.new-post .name {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-weight: 500;
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.new-post .info {
    align-items: center;
    display: inline-flex;
    gap: 4px;
    justify-content: center;
}

.new-post .highlight.reply {
    color: var(--variable-collection-secondary-color-75) !important;
}

.new-post .highlight.editing,
.new-post .highlight.repost {
    color: var(--variable-collection-warning-color-60) !important;
}

.new-post .text-wrapper {
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.new-post .name,
.new-post .text-wrapper.username {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-all; 
}

.new-post .text-wrapper.date {
    white-space: nowrap;
}

.new-post .content-text {
    align-self: stretch;
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    word-break: break-word;
    white-space: pre-line;
    word-wrap: break-word;
}

.new-post .content-text > a {
    color: var(--variable-collection-secondary-color-75);
}

.new-post .actions {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
}

.new-post .media {
    align-items: center;
    border: 1px solid;
    border-color: var(--variable-collection-secondary-color-75);
    border-radius: 8px;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
}

.new-post .div-1 .media-preview {
    border: solid 1px;
    border-color: var(--variable-collection-primary-color-25);
}

.new-post .broom-button,
.new-post .media-button,
.new-post .send-button {
    width: 40px;
    height: 40px;
}

.new-post .broom-button>svg,
.new-post .media-button>svg,
.new-post .send-button>svg {
    width: 24px;
    height: 24px;
}

.new-post .original-post {
    align-items: center;
    align-self: stretch;
    border: 1px solid;
    border-radius: 8px;
    display: flex;
    gap: 8px;
    padding: 8px;
    width: 100%;
}

.new-post .original-post.repost {
    border-color: var(--variable-collection-warning-color-50);
}

.new-post .original-post.reply {
    border-color: var(--variable-collection-secondary-color-60);
}

.new-post .header-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    width: 100%;
}

.new-post .content {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.new-post .content-area {
    min-height: 21px;
    max-height: 280px;
    border: none;
    background-color: unset;
    width: 100%;
    resize: none;
    outline: none;
}

.new-post .content-text .highlighted-tag {
    color: var(--variable-collection-secondary-color-75);
}

.new-post .content-area::-webkit-scrollbar {
    width: 8px;
}

.new-post .content-area::-webkit-scrollbar-track {
    background: none;
}

.new-post .content-area::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: inset 0 0 16px var(--variable-collection-primary-color-30);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
}

.new-post .content-area::-webkit-scrollbar-thumb:hover {
    background: transparent;
    box-shadow: inset 0 0 16px var(--variable-collection-primary-color-35);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 0px solid transparent;
    border-right: 2px solid transparent;
}

.new-post .uploading-label {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    justify-content: flex-end;
}

.new-post .uploading-label .text-wrapper {
    color: var(--variable-collection-secondary-color-75);
    font-size: 14px;
    white-space: nowrap;
}

.new-post .uploading-label > svg > path {
    color: var(--variable-collection-secondary-color-75);
    fill: var(--variable-collection-secondary-color-75);
}

@media only screen and (max-width: 800px) {
    .new-post .text-wrapper.username {
        display: none;
    }
}  

@media only screen and (max-width: 380px) {
    .new-post .media-button,
    .new-post .send-button {
        width: 32px;
        height: 32px;
    }

    .new-post .media-button>svg,
    .new-post .send-button>svg {
        width: 20px;
        height: 20px;
    }
}

@media only screen and (max-width: 360px) {
    .new-post .text-wrapper.username {
        display: none;
    }

    .new-post .div-3 {
        gap: 4px;
    }
}   

@media only screen and (max-width: 330px) {
    .new-post .profile-icon {
        border-radius: 32px;
        height: 32px;
        width: 32px;
    }

    .new-post .broom-button {
        width: 32px;
        height: 32px;
    }

    .new-post .broom-button>svg {
        width: 20px;
        height: 20px;
    }
}   