.search-bar {
    align-items: center;
    justify-content: space-between;
    background-color: var(--variable-collection-primary-color-15);
    border-radius: 8px;
    display: flex;
    gap: 8px;
    overflow: hidden;
    padding: 4px 8px;
    width: 100%;
}

.search-bar .search-section {
    align-items: center;
    display: flex;
    overflow: hidden;
    gap: 8px;
    width: 100%;
}

.search-bar .text-box-wrapper {
    all: unset;
    box-sizing: border-box;
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: 100%;
}

.search-bar:hover {
    outline: 1px solid;
    outline-color: var(--variable-collection-primary-color-35);
}

.search-bar.focus {
    outline: 1px solid;
    outline-color: var(--variable-collection-primary-color-60);
}

.search-bar .close-button > svg > path {
    fill: var(--variable-collection-primary-color-60);
}

.search-bar .close-button:hover > svg > path {
    fill: var(--variable-collection-contrast-color);
}