.artist-menu-card {
    align-items: center;
    border-radius: 8px;
    display: inline-flex;
    gap: 16px;
    justify-content: center;
    padding: 8px 16px;
    user-select: none;
    cursor: pointer;
}

.artist-menu-card .text-wrapper {
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.artist-menu-card.active {
    background-color: var(--variable-collection-primary-color-20);
    pointer-events: none;
}

.artist-menu-card.active .text-wrapper {
    color: var(--variable-collection-secondary-color-75);
}

.artist-menu-card:hover {
    background-color: var(--variable-collection-primary-color-30);
}

.artist-menu-card:hover .text-wrapper {
    color: var(--variable-collection-primary-color-95);
}


.artist-menu-card .profile-icon {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    user-select: none;
}

.artist-menu-card.active .profile-icon {
    outline: 2px solid var(--variable-collection-secondary-color-75);
}

.artist-menu-card:hover .profile-icon {
    outline: 2px solid var(--variable-collection-primary-color-95);
}