.chat-contact-card {
    align-items: center;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    width: 100%;
    gap: 8px;
    cursor: pointer;
}

.chat-contact-card .left-section {
    align-items: center;
    display: inline-flex;
    gap: 16px;
}

.chat-contact-card .text-wrapper {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: var(--variable-collection-contrast-color);
    display: -webkit-box;
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
}

.chat-contact-card .contact-type {
    align-items: center;
    background-color: var(--variable-collection-secondary-color-75);
    border-radius: 4px;
    display: inline-flex;
    justify-content: center;
    padding: 4px 12px;
}

.chat-contact-card .div {
    color: var(--variable-collection-primary-color-5);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.chat-contact-card:hover {
    background-color: var(--variable-collection-primary-color-20);
}

.chat-contact-card .contact-icon {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    outline: 1px solid var(--variable-collection-primary-color-60);
    user-select: none;
}
