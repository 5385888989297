.artist-tracks-box.composite .box-top-section {
    gap: 16px;
    padding: 0px;
}

.artist-tracks-box.composite .search-bar {
    height: 40px;
}

.artist-tracks-box.composite .artist-track-card,
.artist-tracks-box.composite .artist-promotion-track-card {
    padding-left: 0px;
    padding-right: 8px;
}

.artist-tracks-box.composite .artist-track-card:hover,
.artist-tracks-box.composite .artist-promotion-track-card {
    background-color: transparent;
}

@media only screen and (max-width: 380px) {
    .artist-tracks-box.composite {
        gap: 8px;
    }

    .artist-tracks-box.composite .track-box .empty .primary-button {
        width: 65%;
    }

    .artist-tracks-box hr.separator-line {
        width: 160px;
    }

    .artist-tracks-box.composite .box-top-section {
        gap: 8px;
    }

    .artist-tracks-box.composite .search-bar {
        height: 32px;
    }

    .artist-tracks-box.composite .search-bar .text-box-wrapper,
    .artist-tracks-box.composite .note {
        font-family: var(--p-primary-font-family);
        font-size: var(--p-primary-font-size);
        font-style: var(--p-primary-font-style);
        font-weight: var(--p-primary-font-weight);
        letter-spacing: var(--p-primary-letter-spacing);
        line-height: var(--p-primary-line-height);
    }

    .artist-tracks-box.composite .artist-track-card .track-name,
    .artist-tracks-box.composite .artist-promotion-track-card .track-name {
        font-family: var(--h6-primary-font-family);
        font-size: var(--h6-primary-font-size);
        font-style: var(--h6-primary-font-style);
        font-weight: var(--h6-primary-font-weight);
        letter-spacing: var(--h6-primary-letter-spacing);
        line-height: var(--h6-primary-line-height);
    }

    .artist-tracks-box.composite .artist-track-card .track-duration,
    .artist-tracks-box.composite .artist-promotion-track-card .track-duration {
        font-family: var(--p-primary-font-family);
        font-size: var(--p-primary-font-size);
        font-style: var(--p-primary-font-style);
        font-weight: var(--p-primary-font-weight);
        letter-spacing: var(--p-primary-letter-spacing);
        line-height: var(--p-primary-line-height);
    }

    .artist-tracks-box.composite .artist-track-card .left-card-section,
    .artist-tracks-box.composite .artist-promotion-track-card .left-card-section {
        gap: 8px;
    }
    
    .artist-tracks-box.composite .artist-track-card .right-card-section,
    .artist-tracks-box.composite .artist-promotion-track-card .right-card-section {
        gap: 8px;
    }

    .artist-tracks-box.composite .artist-track-card .track-album,
    .artist-tracks-box.composite .artist-promotion-track-card .track-album {
        color: var(--variable-collection-primary-color-60);
    }

    .artist-tracks-box.composite .artist-track-card .main-info,
    .artist-tracks-box.composite .artist-promotion-track-card .main-info {
        gap: 0px;
    }

    .artist-tracks-box.composite .artist-track-card .track-image,
    .artist-tracks-box.composite .artist-promotion-track-card .track-image {
        width: 48px;
        height: 48px;
    }
}

@media only screen and (max-width: 460px) {
    .artist-tracks-box.composite {
        min-width: 288px;
    }
}