.social-media-card {
    align-items: center;
    display: inline-flex;
    gap: 16px;
    justify-content: center;
    padding: 16px;
    cursor: pointer;
    border-radius: 8px;
}

.social-media-card:active {
    opacity: 0.7;
}