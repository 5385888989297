.video-overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    justify-content: center;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: var(--variable-collection-primary-color-5-75);
    z-index: 1000;
}