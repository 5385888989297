.reply-button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
}

.reply-button:active {
    opacity: 0.5;
}

.reply-button > svg > path {
    color: var(--variable-collection-primary-color-60);
}

.reply-button:hover > svg > path,
.reply-button.active > svg > path {
    color: var(--variable-collection-secondary-color-60);
}