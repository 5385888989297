.sigmund-rate-box {
  align-items: flex-start;
  background-color: var(--variable-collection-background-section-color);
  border: 1px solid;
  border-color: var(--variable-collection-section-stroke-color);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  min-width: 345px;
  max-width: 560px;
  width: 100%;
  user-select: none;
}

.sigmund-rate-box .section {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 16px;
  width: 100%;
}

@media only screen and (max-width: 460px) {
  .sigmund-rate-box {
    min-width: 288px;
  }
}