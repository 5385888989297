.switch-component {
  align-items: center;
  background-color: var(--variable-collection-primary-color-15);
  border-radius: 8px;
  display: flex;
  gap: 8px;
  height: 48px;
  justify-content: center;
  overflow: hidden;
  padding: 8px;
  width: 100%;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.switch-component .option {
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
  padding: 16px;
  cursor: pointer;
}

.switch-component .text-wrapper {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--variable-collection-contrast-color);
  display: -webkit-box;
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
}

.switch-component .active {
  background-color: var(--variable-collection-primary-color-30);
}

@media only screen and (max-width: 380px) {
  .switch-component .text-wrapper {
    font-family: var(--p-primary-font-family);
    font-size: var(--p-primary-font-size);
    font-style: var(--p-primary-font-style);
    letter-spacing: var(--p-primary-letter-spacing);
    line-height: var(--p-primary-line-height);
  }

  .switch-component {
    height: 40px;
    gap: 4px;
  }

  .switch-component .option {
    padding: 8px;
  }
}