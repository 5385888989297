.alert-frame {
    align-items: center;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    overflow: hidden;
    padding: 32px;
    width: 100%;
    align-self: stretch;
  }
  
  .alert-frame.background {
    background-color: var(--variable-collection-primary-color-25-5);
  }
  
  .alert-frame>svg>path {
    color: var(--variable-collection-primary-color-95);
  }
  
  .alert-frame .warning {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    width: 100%;
  }
  
  .alert-frame .title {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h4-primary-font-family);
    font-size: var(--h4-primary-font-size);
    font-style: var(--h4-primary-font-style);
    font-weight: var(--h4-primary-font-weight);
    letter-spacing: var(--h4-primary-letter-spacing);
    line-height: var(--h4-primary-line-height);
    text-align: center;
    width: fit-content;
  }
  
  .alert-frame .subtitle {
    align-self: stretch;
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    text-align: center;
  }
  
  .alert-frame .primary-button {
    max-width: 304px;
  }
  
  @media only screen and (max-width: 460px) {
    .in-development {
      height: 100%;
    }
  }
  
  @media only screen and (max-width: 380px) {
    .alert-frame>svg {
      width: 60px;
      height: 60px;
    }
  
    .alert-frame .title {
      font-family: var(--h5-primary-font-family);
      font-size: var(--h5-primary-font-size);
      font-style: var(--h5-primary-font-style);
      font-weight: var(--h5-primary-font-weight);
      letter-spacing: var(--h5-primary-letter-spacing);
      line-height: var(--h5-primary-line-height);
    }
  
    .alert-frame .subtitle {
      font-family: var(--h6-primary-font-family);
      font-size: var(--h6-primary-font-size);
      font-style: var(--h6-primary-font-style);
      font-weight: var(--h6-primary-font-weight);
      letter-spacing: var(--h6-primary-letter-spacing);
      line-height: var(--h6-primary-line-height);
    }
  }