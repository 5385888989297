.curator-request-box {
  align-items: flex-start;
  background-color: var(--variable-collection-background-section-color);
  border: 1px solid;
  border-color: var(--variable-collection-section-stroke-color);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  padding: 16px;
  width: 100%;
  min-width: 345px;
  max-width: 560px;
  user-select: none;
}

.curator-request-box .upper-section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.curator-request-box .title {
  align-self: stretch;
  color: var(--variable-collection-contrast-color);
  font-family: var(--h5-primary-font-family);
  font-size: var(--h5-primary-font-size);
  font-style: var(--h5-primary-font-style);
  font-weight: var(--h5-primary-font-weight);
  letter-spacing: var(--h5-primary-letter-spacing);
  line-height: var(--h5-primary-line-height);
}

.curator-request-box .request-bar {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.curator-request-box .sub-title {
  align-self: stretch;
  color: var(--variable-collection-contrast-color);
  font-family: var(--h6-primary-font-family);
  font-size: var(--h6-primary-font-size);
  font-style: var(--h6-primary-font-style);
  font-weight: var(--h6-primary-font-weight);
  letter-spacing: var(--h6-primary-letter-spacing);
  line-height: var(--h6-primary-line-height);
}

.curator-request-box .primary-button.info:disabled, .primary-button.info[disabled] {
  background-color: var(--variable-collection-primary-color-15);
  border-color: var(--variable-collection-primary-color-15);
  pointer-events: none;
}

@media only screen and (max-width: 460px) {
  .curator-request-box {
    min-width: 288px;
  }
}

@media only screen and (max-width: 380px) {
  .curator-request-box {
    gap: 8px;
  }

  .curator-request-box .upper-section {
    gap: 4px;
  }
  .curator-request-box .progress-bar {
    height: 8px;
  }
  .curator-request-box .progress-bar .progress {
    height: 8px;
  }
}