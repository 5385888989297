.desktop-app-layout {
    align-items: center;
    background: var(--variable-collection-2-background-gradient);
    background-color: var(--variable-collection-primary-color-20);
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    min-height: 100vh;
    min-height: 100svh;
    height: 100%;
    padding: 16px;
    gap: 16px;
}

.desktop-app-layout .content-block {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 12px;
    max-width: 1436px;
    width: 100%;
    height: 100%;
}

.desktop-app-layout .content {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    gap: 12px;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.desktop-app-layout .compose-section {
    display: flex;
    position: sticky;
    top: 16px;
    z-index: 100;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.desktop-app-layout .vertical-section {
    display: flex;
    gap: 12px;
    align-self: stretch;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.desktop-app-layout.full-height .content {
    height: 0px;
}

.desktop-app-layout.full-height {
    height: 100vh;
    height: 100dvh;
}


@media only screen and (min-width: 461px) {
    .desktop-app-layout .compose-section {
        height: 100%;
    }
}

@media only screen and (max-width: 460px) {
    .desktop-app-layout .compose-section {
        width: 100%;
    }
}