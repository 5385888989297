.share-button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
}

.share-button:active {
    opacity: 0.5;
}

.share-button > svg > path {
    color: var(--variable-collection-primary-color-60);
}

.share-button:hover > svg > path {
    color: var(--variable-collection-contrast-color);
}