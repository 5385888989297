.profile-deletion {
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    align-items: flex-start;
    background-color: var(--variable-collection-background-overlay-color);
    border: 2px solid;
    border-color: var(--variable-collection-primary-color-15);
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    gap: 16px;
    padding: 16px;
    overflow: hidden;
    width: 640px;
    z-index: 1000;
}

.profile-deletion .header {
    align-items: flex-start;
    align-self: stretch;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.profile-deletion .text-wrapper {
    color: var(--variable-collection-warning-color-60);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    width: fit-content;
}

.profile-deletion .info {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.profile-deletion .confirmation {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.profile-deletion .username-box {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    gap: 4px;
    width: 100%;
}

.profile-deletion ul {
    padding-inline-start: 20px;
    list-style-type: unset;
    margin-block-start: unset;
    margin-block-end: unset;
}

.profile-deletion .div {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    width: fit-content;
}

.profile-deletion .text-wrapper-2 {
    color: var(--variable-collection-secondary-color-75);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: 600;
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.profile-deletion .input-box {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 16px;
    width: 100%;
}

.profile-deletion .primary-button,
.profile-deletion .primary-button:hover {
    background-color: var(--variable-collection-warning-color-60);
}

.profile-deletion .text-box-wrapper {
    color: var(--variable-collection-primary-color-60);
    background-color: var(--variable-collection-primary-color-20);
    border: 1px solid;
    border-color: var(--variable-collection-primary-color-35);
    border-radius: 8px;
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    height: 48px;
    padding: 8px 16px;
    width: 100%;
}


.profile-deletion .text-box-wrapper:hover {
    border-color: var(--variable-collection-primary-color-60);
}


.profile-deletion .text-box-wrapper:invalid {
    border-color: var(--variable-collection-warning-color-50);
}


.profile-deletion .text-box-wrapper.invalid {
    border-color: var(--variable-collection-warning-color-50);
}


.profile-deletion .text-box-wrapper.invalid:hover {
    border-color: var(--variable-collection-primary-color-60);
}


.profile-deletion .text-box-wrapper:focus {
    outline: none;
}

.profile-deletion .close-button>svg>path {
    fill: var(--variable-collection-primary-color-60);
}

.profile-deletion .close-button:hover>svg>path {
    fill: var(--variable-collection-primary-color-95);
}

@media only screen and (min-width: 617px) and (max-width: 692px) {
    .profile-deletion {
        width: 440px;
    }
}

@media only screen and (max-width: 616px) {
    .profile-deletion,
    .profile-deletion .info {
        gap: 12px;
    }
}

@media only screen and (min-width: 461px) and (max-width: 616px) {
    .profile-deletion {
        width: 375px;
    }
}

@media only screen and (min-width: 381px) and (max-width: 460px) {
    .profile-deletion {
        width: 350px;
    }
}

@media only screen and (max-width: 380px) {
    .profile-deletion {
        width: 290px;
    }
}