.referral-box {
    align-items: flex-start;
    background-color: var(--variable-collection-background-section-color);
    border: 1px solid;
    border-color: var(--variable-collection-section-stroke-color);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    padding: 16px;
    width: 100%;
}

.referral-box .box-title {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.referral-box .url-section {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 8px;
    width: 100%;
}

.referral-box .url-box {
    align-items: center;
    background-color: var(--variable-collection-primary-color-15);
    border-radius: 8px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 8px;
    height: 40px;
    overflow: hidden;
    padding: 8px 16px;
}

.referral-box .url-wrapper {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: var(--variable-collection-primary-color-60);
    display: -webkit-box;
    flex: 1;
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
    overflow: hidden;
    text-overflow: ellipsis;
}

.referral-box .tool-tip-style {
    background-color: var(--variable-collection-primary-color-10);
    border: 2px solid var(--variable-collection-primary-color-5);
    border-radius: 8px;
}

.referral-box .tip-text-wrapper {
    align-self: stretch;
    color: var(--variable-collection-contrast-color);
    font-family: var(--p-primary-font-family);
    font-size: var(--p-primary-font-size);
    font-style: var(--p-primary-font-style);
    font-weight: var(--p-primary-font-style);
    letter-spacing: var(--p-primary-letter-spacing);
    line-height: var(--p-primary-line-height);
    text-align: center;
}

@media only screen and (max-width: 380px) {
    .referral-box .url-box {
        height: 32px;
    }
}