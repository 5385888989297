.masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    gap: 4px;
}

.masonry-grid-column {
    display: flex;
    flex-direction: column;
    background-clip: padding-box;
    gap: 4px;
}

.media-item {
    cursor: pointer;
    margin-bottom: -4px;
    width: 100%;
}

.media-item .video-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
}

.media-item .video-wrapper .mute-button {
    all: unset;
    box-sizing: border-box;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    bottom: 20px;
    right: 16px;
    background-color: var(--variable-collection-primary-color-5-75);
    border-radius: 36px;
    padding: 8px;
    cursor: pointer;
}

.media-item .video-wrapper .mute-button:active {
    opacity: 0.7;
}

.media-item .hero {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.media-item .unavailable-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 24px 32px;
    border: solid 1px;
    border-color: var(--variable-collection-primary-color-35);
    border-radius: 8px;
    overflow: hidden;
}

.media-item .unavailable-section > svg > path {
    fill: var(--variable-collection-primary-color-35);
}

.media-item .unavailable-section .unavailable-text {
    color: var(--variable-collection-primary-color-35);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.media-item .hero .video-label {
    align-items: center;
    background-color: var(--variable-collection-primary-color-5-75);
    border: 2px solid;
    border-color: var(--variable-collection-contrast-color);
    border-radius: 50%;
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    padding: 12px;
    cursor: pointer;
}

.media-item svg.loader-box-icon,
.media-item svg>path {
    fill: var(--variable-collection-contrast-color);
    color: var(--variable-collection-contrast-color);
}

.media-preview {
    border-radius: 8px;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.media-preview:fullscreen {
    object-fit: contain;
}

@media only screen and (max-width: 380px) {
    .media-item .unavailable-section {
        padding: 8px 16px;
    }
}