.circle-arrow-button {
    all: unset;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: all ease-in-out 80ms;
}

.circle-arrow-button > svg > path {
    color: var(--variable-collection-approve-color-60);
}

.circle-arrow-button:disabled, .circle-arrow-button[disabled] {
    opacity: 50%;
    pointer-events: none;
    user-select: none;
    cursor: default;
}

.circle-arrow-button:active {
    opacity: 50%;
}