.dropdown-box {
    display: block;
    width: 100%;
    user-select: none;
}

.dropdown-box .control {
    display: flex;
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--variable-collection-primary-color-35);
    background: var(--variable-collection-primary-color-15);
}

.dropdown-box .control > svg > path {
    fill: var(--variable-collection-primary-color-35);
}

.dropdown-box .control:hover {
    border: 1px solid var(--variable-collection-primary-color-60);
}

.dropdown-box .control:hover > svg > path {
    fill: var(--variable-collection-primary-color-60);
}

.dropdown-box .active-control {
    display: flex;
    width: 100%;
    height: 48px;
    padding: 8px 16px 9px 16px;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top: 1px solid var(--variable-collection-primary-color-60);
    border-left: 1px solid var(--variable-collection-primary-color-60);
    border-right: 1px solid var(--variable-collection-primary-color-60);
    background: var(--variable-collection-primary-color-15);
}

.dropdown-box .invalid-control {
    border: 1px solid var(--variable-collection-warning-color-50);
}

.dropdown-box .active-control > svg > path {
    fill: var(--variable-collection-primary-color-60);
}


.dropdown-box .text-wrapper  {
    color: var(--variable-collection-primary-color-60);
    font-family: var(--h6-primary-font-family);
    font-size: var(--h6-primary-font-size);
    font-style: var(--h6-primary-font-style);
    font-weight: var(--h6-primary-font-weight);
    letter-spacing: var(--h6-primary-letter-spacing);
    line-height: var(--h6-primary-line-height);
}

.dropdown-box .menu {
    display: flex;
    position: absolute;
    z-index: 1000;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    border-radius: 0px 0px 8px 8px;
    border-right: 1px solid var(--variable-collection-primary-color-60);
    border-bottom: 1px solid var(--variable-collection-primary-color-60);
    border-left: 1px solid var(--variable-collection-primary-color-60);
    background: var(--variable-collection-primary-color-15);
}

.dropdown-box .option {
    display: flex;
    gap: 8px;
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    align-items: center;
    color: var(--variable-collection-contrast-color);
    background: var(--variable-collection-primary-color-15);
}

.dropdown-box .option.active {
    padding: 8px 16px 8px 8px;
}

.dropdown-box .option:hover {
    background-color: var(--variable-collection-primary-color-30);
}

@media only screen and (max-width: 380px) {
    .dropdown-box .control, .dropdown-box .active-control, .dropdown-box .option {
        height: 40px;
    }
    .dropdown-box .control > svg  {
        width: 24px;
        height: 24px;
    }
    .dropdown-box .active-control > svg  {
        width: 24px;
        height: 24px;
    }
}