.fans-info-box {
  align-items: center;
  justify-content: center;
  background-color: var(--variable-collection-background-section-color);
  border: 1px solid;
  border-color: var(--variable-collection-section-stroke-color);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 16px;
  width: 100%;
  min-height: 357px;
}

.fans-info-box .total-sections {
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.fans-info-box .total-sections.compact {
  gap: 32px;
  justify-content: unset;
}

.fans-info-box .total-sections.left-compact {
  gap: 32px;
  justify-content: flex-start;
}

.fans-info-box .total-sections-wrapper {
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.fans-info-box .stats {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 32px;
  justify-content: center;
  width: 100%;
}

.fans-info-box .stats.compact {
  flex-direction: column;
}

.fans-info-box .double-card-section {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
}

.fans-info-box .double-card-section.full-width {
  width: 100%;
}

.fans-info-box .double-card-title {
  align-self: stretch;
  color: var(--variable-collection-contrast-color);
  font-family: var(--h5-primary-font-family);
  font-size: var(--h5-primary-font-size);
  font-style: var(--h5-primary-font-style);
  font-weight: var(--h5-primary-font-weight);
  letter-spacing: var(--h5-primary-letter-spacing);
  line-height: var(--h5-primary-line-height);
  text-wrap: nowrap;
}

@media only screen and (max-width: 380px) {
  .fans-info-box .info-card .subtitle {
    font-family: var(--h4-primary-font-family);
    font-size: var(--h4-primary-font-size);
    font-style: var(--h4-primary-font-style);
    font-weight: var(--h4-primary-font-weight);
    letter-spacing: var(--h4-primary-letter-spacing);
    line-height: var(--h4-primary-line-height);
  }

  .fans-info-box .double-card-title {
    text-wrap: wrap;
  }

  .fans-info-box .stats.compact {
    gap: 16px;
  }

  .fans-info-box .info-double-card .bottom-card,
  .fans-info-box .info-double-card .top-card,
  .fans-info-box .info-double-card .double-card-section {
    gap: 4px;
  }
}