.broadcasting-box {
    align-items: center;
    background-color: var(--variable-collection-background-section-color);
    border: 1px solid;
    border-color: var(--variable-collection-section-stroke-color);
    border-radius: 16px;
    display: flex;
    gap: 8px;
    overflow: hidden;
    padding: 16px;
    width: 100%;
    min-width: 345px;
    max-width: 560px;
    user-select: none;
}

.broadcasting-box .frame {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    overflow: hidden;
    width: 100%;
}

.broadcasting-box .section {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 8px;
    overflow: hidden;
    width: 100%;
}

.broadcasting-box .label-section {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 8px;
    overflow: hidden;
    width: 100%;
}

.broadcasting-box .label-text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    text-align: center;
    white-space: nowrap;
}

.broadcasting-box .text-wrapper {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    text-align: center;
    white-space: nowrap;
}

.broadcasting-box .device {
    color: var(--variable-collection-secondary-color-50);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-all;
    flex: 1;
}

.broadcasting-box .left-section {
    align-items: center;
    display: inline-flex;
    gap: 8px;
}

.broadcasting-box .left-section>svg>path {
    color: var(--variable-collection-contrast-color);
}

@media only screen and (max-width: 460px) {
    .broadcasting-box {
        min-width: 288px;
    }
}

@media only screen and (max-width: 380px) {
    .broadcasting-box .frame {
        gap: 4px;
    }
    .broadcasting-box .label-section {
        gap: 0px;
        flex-direction: column;
        align-items: flex-start;
    }
    .broadcasting-box {
        padding: 12px 16px;
    }
    .broadcasting-box .text-wrapper {
        font-family: var(--h6-primary-font-family);
        font-size: var(--h6-primary-font-size);
        font-style: var(--h6-primary-font-style);
        font-weight: var(--h6-primary-font-weight);
        letter-spacing: var(--h6-primary-letter-spacing);
        line-height: var(--h6-primary-line-height);
    }
    .broadcasting-box .device {
        font-family: var(--h6-primary-font-family);
        font-size: var(--h6-primary-font-size);
        font-style: var(--h6-primary-font-style);
        font-weight: var(--h6-primary-font-weight);
        letter-spacing: var(--h6-primary-letter-spacing);
        line-height: var(--h6-primary-line-height);
    }
    .broadcasting-box .left-section > svg {
        width: 24px;
        height: 24px;
    }
    
}