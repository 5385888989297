.trends {
    align-items: flex-start;
    background-color: var(--variable-collection-background-section-color);
    border: 1px solid;
    border-color: var(--variable-collection-section-stroke-color);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: hidden;
    padding: 16px;
    width: 100%;
}

.trends .header {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    width: 100%;
}

.trends .div {
    color: var(--variable-collection-contrast-color);
    font-family: var(--h5-primary-font-family);
    font-size: var(--h5-primary-font-size);
    font-style: var(--h5-primary-font-style);
    font-weight: var(--h5-primary-font-weight);
    letter-spacing: var(--h5-primary-letter-spacing);
    line-height: var(--h5-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.trends .content {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}